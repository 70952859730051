import React, { Component } from 'react';
import { Formik, Form, Field } from "formik";
import { config } from '../config';
import fetch from 'isomorphic-fetch';
import Calendar from 'react-calendar';
import DataTable from 'react-data-table-component';
import 'react-calendar/dist/Calendar.css';
import 'react-data-grid/dist/react-data-grid.css';
import axios from 'axios';
import { polyfill } from 'es6-promise'; polyfill();

let basic = 'Basic ';
let basicAuthPDF = 'Basic d2VidWk6d2lrb3Y=';
const date = new Date();

let val = {
    wingear: {
        from: date.getFullYear() + '-' + (date.getMonth() + 1) + '-1',
        to: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    },

    orbiflex: {
        from: date.getFullYear() + '-' + (date.getMonth() + 1) + '-1',
        to: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    },
}

const columnsOrbiflex = [
    { name: 'Datum', selector: 'date', sortable: true },
    { name: 'Industry', selector: 'industry', sortable: true },
    { name: 'Driven machine', selector: 'drivenMachine', sortable: true },
    { name: 'Option A', selector: 'optionA', sortable: true },
    { name: 'Option B', selector: 'optionB', sortable: true },
    { name: 'Option C', selector: 'optionC', sortable: true },
    { name: 'Option D', selector: 'optionD', sortable: true },
    { name: 'Motor power', selector: 'power', sortable: true },
    { name: 'Output shaft torque', selector: 'lst', sortable: true },
    { name: 'Effective operating period', selector: 'dailyPeriod', sortable: true },
    { name: 'Application factor KA', selector: 'ka', sortable: true },
    { name: 'IP adresa', selector: 'ip', sortable: true }
];

const columnsRfqOrbiflex = [
    { name: 'Datum', selector: 'date', sortable: true },    
    { name: 'E-mail', selector: 'email', sortable: true },
    { name: 'Telefon', selector: 'phone', sortable: true },
    { name: 'Zpráva', selector: 'message', sortable: true },
    { name: 'PDF', cell: row => <button type="button" id={row.configuration} onClick={e => {
        axios(`${config.server}/api/pdf/load?id=${e.target.id}`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
              "Authorization": basicAuthPDF,              
            }            
          })    
          .then(response => {
            var newBlob = new Blob([response.data], {type: "application/pdf"})
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob, "Gearbox.pdf");
              this.setState({ loading : false });
              return;
            } 
      
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download="Gearbox.pdf";
            link.click();
            setTimeout(function(){
              window.URL.revokeObjectURL(data);
            }, 1000);
            
            this.setState({ loading : false });
          })
          .catch(error => {
              console.log(error);
          })
    }} >PDF</button> }
];

const columnsWingear = [
    { name: 'Datum', selector: 'date', sortable: true },
    { name: 'Industry', selector: 'industry', sortable: true },
    { name: 'Driven machine', selector: 'drivenMachine', sortable: true },
    { name: 'Option A', selector: 'optionA', sortable: true },
    { name: 'Option B', selector: 'optionB', sortable: true },
    { name: 'Option C', selector: 'optionC', sortable: true },
    { name: 'Option D', selector: 'optionD', sortable: true },
    { name: 'High speed shaft', selector: 'highspeedShaft', sortable: true },
    { name: 'Low speed shaft', selector: 'lowspeedShaft', sortable: true },
    { name: 'Motor power', selector: 'power', sortable: true },
    { name: 'Output shaft torque', selector: 'lst', sortable: true },
    { name: 'Effective operating period', selector: 'dailyPeriod', sortable: true },
    { name: 'Application factor KA', selector: 'ka', sortable: true },
    { name: 'IP adresa', selector: 'ip', sortable: true }
];

const columnsRfqWingear = [
    { name: 'Datum', selector: 'date', sortable: true },    
    { name: 'E-mail', selector: 'email', sortable: true },
    { name: 'Telefon', selector: 'phone', sortable: true },
    { name: 'Zpráva', selector: 'message', sortable: true },
    { name: 'PDF', cell: row => <button type="button" id={row.configuration} onClick={e => {
        axios(`${config.server}/wingear/api/pdf/load?id=${e.target.id}`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
              "Authorization": basicAuthPDF,              
            }            
          })    
          .then(response => {
            var newBlob = new Blob([response.data], {type: "application/pdf"})
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob, "Gearbox.pdf");
              this.setState({ loading : false });
              return;
            } 
      
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download="Gearbox.pdf";
            link.click();
            setTimeout(function(){
              window.URL.revokeObjectURL(data);
            }, 1000);
            
            this.setState({ loading : false });
          })
          .catch(error => {
              console.log(error);
          })
    }} >PDF</button> }
];

class Stats extends Component {    
    state = {        
        wingear: {
            from: val.wingear.from,
            to: val.wingear.to
        },

        orbiflex: {
            from: val.orbiflex.from,
            to: val.orbiflex.to
        },

        isAuthenticted: false,
        username: '',
        password: '',

        wingearAccesses: 0,
        orbiflexAccesses: 0,

        requestsOrbiflex: 0,
        configurationsOrbiflex: 0,
        rfqsOrbiflex: 0,
        conversionOrbiflex: 0,

        requestsWingear: 0,
        configurationsWingear: 0,
        rfqsWingear: 0,
        conversionWingear: 0,

        isLoading: false,

        displayOrbiflex: false,        
        displayWingear: false,        

        calendarOrbiflexFrom: false,
        calendarOrbiflexTo: false,
        calendarWingearFrom: false,
        calendarWingearTo: false,

        orbiflexFrom: new Date(),
        orbiflexTo: new Date(),
        wingearFrom: new Date(),
        wingearTo: new Date(),

        requestsOrbiflex_results: [],
        configurationsOrbiflex_results: [],
        requestsWingear_results: [],
        configurationsWingear_results: [],

        orbiflex_data: [],
        orbiflex_rfq_data: [],
        wingear_data: [],
        wingear_rfq_data: []
      };  

    componentWillMount() {
        if (localStorage.getItem('token') === null) {
            localStorage.setItem('token', '');
        }
    }
    
    componentDidMount() {
        this.setState({isLoading: true});

        fetch(config.server + '/orbiflex/api/stats/monthly?from=' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-1&to=' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + new Date(date.getFullYear(), date.getMonth(), 0).getDate(), {
            headers: new Headers({
            "Authorization": basic + localStorage.getItem('token')
            }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            this.setState({ orbiflexAccesses: result.accesses, isLoading: false })
        });

        //this.loadOrbiflexData();
        //this.loadWingearData();
    }

    loadOrbiflexData = () => {        
        fetch(config.server + '/orbiflex/api/stats/requests/json?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    { 
                        date: res.date.replace('+0000', ''), 
                        industry: res.industry, 
                        drivenMachine: res.application, 
                        optionA: res.optionAsize, 
                        optionB: res.optionBsize, 
                        optionC: res.optionCsize, 
                        optionD: res.optionDsize, 
                        highspeedShaft: res.highspeedPosition,
                        lowspeedShaft: res.lowspeedPosition, 
                        power: res.pwr.toFixed(2), 
                        lst: res.lst.toFixed(2), 
                        dailyPeriod: res.effectivePeriod, 
                        ka: res.ka, 
                        ip: res.ip 
                    }                    
                )
            })
            )
            this.setState({ orbiflex_data: results });
        });        

        fetch(config.server + '/orbiflex/api/stats/requests/json?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    <div>{res.id}</div>
                )
            })
            )
            this.setState({ requestsOrbiflex_results: results });
        }); 

        fetch(config.server + '/orbiflex/api/stats/configurations/json?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    <div>{res.date}</div>
                )
            })
            )
            this.setState({ configurationsOrbiflex_results: results, isLoading: false });
        });

        fetch(config.server + '/orbiflex/api/stats?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {            
            this.setState({ requestsOrbiflex: result.searchedGearboxes, configurationsOrbiflex: result.configurations, rfqsOrbiflex: result.rfqs, conversionOrbiflex: result.conversion, isLoading: false });
        });

        fetch(config.server + '/orbiflex/api/stats/customers/json?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    { 
                        configuration: res.configuration,
                        date: res.date.replace('+0000', ''), 
                        email: res.email,
                        phone: res.phone,
                        message: res.message
                    }                    
                )
            })
            )
            this.setState({ orbiflex_rfq_data: results });
        });
    }

    loadWingearData = () => {        
        /*
        fetch(config.server + '/wingear/api/stats/monthly?month=' + (date.getMonth() + 1), {
            headers: new Headers({
            "Authorization": basic
            }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            this.setState({ wingearAccesses: result });
        });
        */

        fetch(config.server + '/wingear/api/stats/requests/json?from=' + val.wingear.from + '&to=' + val.wingear.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    <div>{res.id}</div>
                )
            })
            )
            this.setState({ requestsWingear_results: results, isLoading: false });
        }); 

        fetch(config.server + '/wingear/api/stats/requests/json?from=' + val.wingear.from + '&to=' + val.wingear.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    { 
                        date: res.date.replace('+0000', ''), 
                        industry: res.industry, 
                        drivenMachine: res.application, 
                        optionA: res.optionAsize, 
                        optionB: res.optionBsize, 
                        optionC: res.optionCsize, 
                        optionD: res.optionDsize, 
                        highspeedShaft: res.highspeedPosition,
                        lowspeedShaft: res.lowspeedPosition, 
                        power: res.pwr.toFixed(2), 
                        lst: res.lst.toFixed(2), 
                        dailyPeriod: res.effectivePeriod, 
                        ka: res.ka, 
                        ip: res.ip 
                    }                    
                )
            })
            )
            this.setState({ wingear_data: results });
        });

        fetch(config.server + '/wingear/api/stats/configurations/json?from=' + val.wingear.from + '&to=' + val.wingear.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    <div>{res.date}</div>
                )
            })
            )
            this.setState({ configurationsWingear_results: results });
        });

        fetch(config.server + '/wingear/api/stats?from=' + val.wingear.from + '&to=' + val.wingear.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            this.setState({ requestsWingear: result.searchedGearboxes, configurationsWingear: result.configurations, rfqsWingear: result.rfqs, conversionWingear: result.conversion, isLoading: false });
        }); 
        
        fetch(config.server + '/wingear/api/stats/customers/json?from=' + val.wingear.from + '&to=' + val.wingear.to, {
            headers: new Headers({
              "Authorization": basic + localStorage.getItem('token')
            }),
          })
          .then(response => {
              return response.json();
        }).then(result => {
            let results = result.map((res => {
                return (
                    { 
                        configuration: res.configuration,
                        date: res.date.replace('+0000', ''), 
                        email: res.email,
                        phone: res.phone,
                        message: res.message
                    }                    
                )
            })
            )
            this.setState({ wingear_rfq_data: results });
        });
    }

    render() {
        return (            
            <Formik initialValues={val} >
                {({ props, values, setFieldValue }) => (                
                <Form >
                    {(localStorage.getItem('token').length > 0) ? (
                    <React.Fragment>
                    <div>
                        <div><span>Počet unikátních přístupů na konfigurátory {date.getMonth() + 1}/{date.getFullYear()}:&nbsp;</span><span>{this.state.orbiflexAccesses}</span></div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <h3>OrbiFlex</h3>                        
                        <br />
                        <div>                            
                            <div>
                                <span>Období od:</span>
                                &nbsp;                                
                                <Field name="orbiflex.from" onChange={(e) => {
                                    let prop = {...this.state.orbiflex};
                                    prop.from = e.target.value;
                                    this.setState({ orbiflex : prop });
                                    setFieldValue('orbiflex.from', e.target.value);
                                }} />
                                &nbsp;
                                <button type="button" id="orbiflexFromBtn" onClick={(e) => { 
                                    this.setState({calendarOrbiflexFrom: true});
                                }} >Vybrat</button>
                                {(this.state.calendarOrbiflexFrom) ? (
                                <Calendar value={this.state.orbiflexFrom} onChange={(date) => {
                                    this.setState({orbiflexFrom: date, calendarOrbiflexFrom: false});
                                    let prop = {...this.state.orbiflex};
                                    prop.from = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                                    this.setState({ orbiflex : prop });
                                    setFieldValue('orbiflex.from', date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
                                }} />
                                ) : "" }
                                &nbsp;
                                <span>do:</span>
                                &nbsp;
                                <Field name="orbiflex.to" onChange={(e) => {
                                    let prop = {...this.state.orbiflex};
                                    prop.to = e.target.value;
                                    this.setState({ orbiflex : prop });
                                    setFieldValue('orbiflex.to', e.target.value);
                                }} />
                                &nbsp;
                                <button type="button" id="orbiflexToBtn" onClick={(e) => { 
                                    this.setState({calendarOrbiflexTo: true});
                                }} >Vybrat</button>
                                {(this.state.calendarOrbiflexTo) ? (
                                <Calendar value={this.state.orbiflexTo} onChange={(date) => {
                                    this.setState({orbiflexTo: date, calendarOrbiflexTo: false});
                                    let prop = {...this.state.orbiflex};
                                    prop.to = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                                    this.setState({ orbiflex : prop });
                                    setFieldValue('orbiflex.to', date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
                                }} />
                                ) : "" }
                                &nbsp;
                                <button type="button" id="orbiflexFilter" onClick={(e) => {
                                    this.setState({isLoading: true, displayOrbiflex: true});
                                    val.orbiflex.to = this.state.orbiflex.to;
                                    val.orbiflex.from = this.state.orbiflex.from;
                                    setFieldValue('orbiflex.to', val.orbiflex.to);
                                    setFieldValue('orbiflex.from', val.orbiflex.from);       
                                    this.loadOrbiflexData();
                                }}>Generovat</button>
                            </div>
                            {(this.state.displayOrbiflex) ? (
                            <React.Fragment>
                            <p></p>
                            <div>
                                <span>Počet dokončených konfigurací:&nbsp;</span>
                                <span>{this.state.requestsOrbiflex}</span>
                                <span style={{paddingLeft: "2.0em"}}>
                                    <a href={config.server + '/orbiflex/api/stats/requests/csv?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to}>CSV</a>
                                </span>
                            </div>
                            <div>
                                <span>Počet stažených PDF:&nbsp;</span>
                                <span>{this.state.configurationsOrbiflex}</span>
                                <span style={{paddingLeft: "2.0em"}}>
                                    <a href={config.server + '/orbiflex/api/stats/configurations/csv?from=' + val.orbiflex.from + '&to=' + val.orbiflex.to}>CSV</a>
                                </span>
                            </div>
                            <div>
                                <span>Počet RFQ:&nbsp;</span>
                                <span>{this.state.rfqsOrbiflex}</span>                                
                            </div>                            
                            <div>
                                <span>Konverze:&nbsp;</span>
                                <span>{this.state.conversionOrbiflex.toFixed(2)} %</span>
                            </div>
                            <br />
                            <br />
                            <div>
                                <h4>RFQs</h4>
                                <hr />
                                <DataTable columns={columnsRfqOrbiflex} data={this.state.orbiflex_rfq_data} />
                            </div>
                            <br />
                            <br />
                            <div>
                                <h4>Dokončené konfigurace</h4>
                                <hr />
                                <DataTable columns={columnsOrbiflex} data={this.state.orbiflex_data} />
                            </div>
                            </React.Fragment>
                            ) : "" }
                        </div>
                        <p>&nbsp;</p>
                        <h3>Wingear</h3>                        
                        <br />
                        <div>                            
                            <div>
                                <span>Období od:</span>
                                &nbsp;
                                <Field name="wingear.from" onChange={(e) => {
                                    let prop = {...this.state.wingear};
                                    prop.from = e.target.value;
                                    this.setState({ wingear : prop });
                                    setFieldValue('wingear.from', e.target.value);
                                }} />
                                &nbsp;
                                <button type="button" id="wingearFromBtn" onClick={(e) => { 
                                    this.setState({calendarWingearFrom: true});
                                }} >Vybrat</button>
                                {(this.state.calendarWingearFrom) ? (
                                <Calendar value={this.state.wingearFrom} onChange={(date) => {
                                    this.setState({wingearFrom: date, calendarWingearFrom: false});
                                    let prop = {...this.state.wingear};
                                    prop.from = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                                    this.setState({ wingear : prop });
                                    setFieldValue('wingear.from', date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
                                }} />
                                ) : "" }
                                &nbsp;
                                <span>do:</span>
                                &nbsp;
                                <Field name="wingear.to" onChange={(e) => {
                                    let prop = {...this.state.wingear};
                                    prop.to = e.target.value;
                                    this.setState({ wingear : prop });
                                    setFieldValue('wingear.to', e.target.value);
                                }} />
                                &nbsp;
                                <button type="button" id="wingearToBtn" onClick={(e) => { 
                                    this.setState({calendarWingearTo: true});
                                }} >Vybrat</button>
                                {(this.state.calendarWingearTo) ? (
                                <Calendar value={this.state.wingearTo} onChange={(date) => {
                                    this.setState({wingearTo: date, calendarWingearTo: false});
                                    let prop = {...this.state.wingear};
                                    prop.to = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                                    this.setState({ wingear : prop });
                                    setFieldValue('wingear.to', date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
                                }} />
                                ) : "" }
                                &nbsp;
                                <button type="button" id="wingearFilter" onClick={(e) => {                                    
                                    this.setState({isLoading: true, displayWingear: true});
                                    val.wingear.to = this.state.wingear.to;
                                    val.wingear.from = this.state.wingear.from;   
                                    setFieldValue('wingear.to', val.wingear.to);
                                    setFieldValue('wingear.from', val.wingear.from);
                                    this.loadWingearData();
                                }}>Generovat</button>
                            </div>
                            {(this.state.displayWingear) ? (
                            <React.Fragment>
                            <p></p>
                            <div>
                                <span>Počet dokončených konfiguraci:&nbsp;</span>
                                <span>{this.state.requestsWingear}</span>
                                <span style={{paddingLeft: "2.0em"}}>
                                    <a href={config.server + '/wingear/api/stats/requests/csv?from=' + val.wingear.from + '&to=' + val.wingear.to}>CSV</a>
                                </span>
                            </div>
                            <div>
                                <span>Počet stažených PDF:&nbsp;</span>
                                <span>{this.state.configurationsWingear}</span>
                                <span style={{paddingLeft: "2.0em"}}>
                                    <a href={config.server + '/wingear/api/stats/configurations/csv?from=' + val.wingear.from + '&to=' + val.wingear.to}>CSV</a>
                                </span>
                            </div>
                            <div>
                                <span>Počet RFQ:&nbsp;</span>
                                <span>{this.state.rfqsWingear}</span>
                            </div>
                            <div>
                                <span>Konverze:&nbsp;</span>
                                <span>{this.state.conversionWingear.toFixed(2)} %</span>
                            </div>
                            <br />
                            <br />
                            <div>
                                <h4>RFQs</h4>
                                <hr />
                                <DataTable columns={columnsRfqWingear} data={this.state.wingear_rfq_data} />
                            </div>
                            <br />
                            <br />
                            <div>
                                <h4>Dokončené konfigurace</h4>
                                <hr />
                                <DataTable columns={columnsWingear} data={this.state.wingear_data} />
                            </div>

                            </React.Fragment>
                            ) : "" }
                        </div>
                    </div>

                    <div id="js_loading" className={
            (this.state.isLoading === true) ?
            "loading loading--shown" : "loading"}></div>
                </React.Fragment>
                ) : (
                <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <table>
                            <tr>
                                <td>Uživatelské jméno&nbsp;</td>
                                <td>
                                    <Field name="username" onChange={(e) => {
                                        this.setState({username: e.target.value});
                                        setFieldValue('username', e.target.value);
                                    }} />
                                </td>
                            </tr>
                            <tr>
                                <td>Heslo&nbsp;</td>
                                <td>
                                    <Field name="password" type="password" onChange={(e) => {
                                        this.setState({password: e.target.value});
                                        setFieldValue('password', e.target.value);
                                    }} />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <p>&nbsp;</p>                                        
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <button type="button" id="login" onClick={(e) => {
                            fetch(config.server + '/api/authenticate', {
                                method: 'POST',
                                headers: {                              
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                
                                    "username": this.state.username,
                                    "password": this.state.password
                                
                                })
                            })
                            .then(response => {
                                return response.json();            
                            })
                            .then((data) => {
                                if (data.token.length > 0) {
                                    localStorage.setItem('token', data.token);
                                    this.setState({isAuthenticted: true});
                                } else {
                                    this.setState({username: ''});
                                    setFieldValue('username', '');
                                    this.setState({password: ''});
                                    setFieldValue('password', '');
                                }
                            })
                        }}>Přihlásit se</button>
                    </div>
                </div>
                )}
                </Form>
                )}
            </Formik>            
        );
    }
}

export default Stats;