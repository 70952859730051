import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { config } from './config'
import axios from 'axios';
import fetch from 'isomorphic-fetch';
import { polyfill } from 'es6-promise'; polyfill();

let basic = 'Basic d2VidWk6d2lrb3Y=';

let model = {
  gearboxParams: {
      lss: 0,
      hss: 0,
      lst: 0,
      hst: 0,
      pwr: 0,
      ratio: 0,
    },

    gearboxes: {
        optimal: {
            stages: 0,
            size: 0,
            trueRatio: 0,
            nominalRatio: 0,
            deviation: 0,
            ka: 0,
            ratedTorque: 0,
            lss: 0,
            lstTrue: 0,
            weight: 0
        },

        optimalSecond: {
            stages: 0,
            size: 0,
            trueRatio: 0,
            nominalRatio: 0,
            deviation: 0,
            ka: 0,
            ratedTorque: 0,
            lss: 0,
            lstTrue: 0,
            weight: 0
        },

        suboptimal: {
            stages: 0,
            size: 0,
            trueRatio: 0,
            nominalRatio: 0,
            deviation: 0,
            ka: 0,
            ratedTorque: 0,
            lss: 0,
            lstTrue: 0,
            weight: 0
        },

        suboptimalSecond: {
            stages: 0,
            size: 0,
            trueRatio: 0,
            nominalRatio: 0,
            deviation: 0,
            ka: 0,
            ratedTorque: 0,
            lss: 0,
            lstTrue: 0,
            weight: 0
        }
    },

  unitspwr: {    
      current: 1,  
      kw: 1,
      hp: 1.34102
  },

  unitshst: {
    current: 2,
    nm: 1000,
    knm: 1,
    ftlb: 737.562
  },

  unitslst: {
    current: 2,
    nm: 1000,
    knm: 1,
    ftlb: 737.562
  },

  unitsmst: {
    current: 2,
    nm: 1000,
    knm: 1,
    ftlb: 737.562
  },

  shaftTypeHelical: 'HRL',
  shaftTypeBevel: 'BFL',
  industry: 0,  //list
  application: 0,   //list
  type: "helical",
  fixedParam: "pwr",
  dusty: false,
  explosive: false,
  offshore: false,
  ka: 0,
  recommendedKa: 0,
  primeMover: 0,    //list
  mstatic: 0,
  cplss: 0,
  cphss: 0,
  cs: 0,
  angle1: 0,
  angle2: 0,
  angle3: 0,
  angle4: 0,
  bearingLife: 0,
  mountingArrangement: 0,   //list
  highspeedShaft: 0,    //list
  lowspeedShaft: 0,     //list
  lubrication: 0,
  climate: 0,   //list
  elevation: 0,     //list
  lubricationUnit: false,
  highspeedCoupling: false,
  highspeedCouplingType: 0, //list
  lowspeedCoupling: false,
  lowspeedCouplingType: 0,  //list
  backStop: false,
  safetyBrake: false,
  electricMotor: false,
  frequencyConvertor: false,
  flangeMotor: false,
  outputFlange: false,
  inputCooling: false,
  torqueSupport: false,
  coolingByWater: false,
  dutycycle: 0, //list
  ambienttemperature: 0,    //list
  utilization: 0,   //list
  airflow: 0,   //list
  hssRadial: 0,
  hssRadialUnits: 'kN',
  lssRadial: 0,
  lssRadialUnits: 'kN',
  hssAxial: 0,
  hssAxialUnits: 'kN',
  hssAxialIn: true,
  lssAxial: 0,
  lssAxialUnits: 'kN',
  lssAxialIn: true,

  effectiveDaily: {
    selected: '',
    lessThan3: 0 ,
    between3And10: 0,
    higherThan10: 0
  },

  notes: ''
};

let indexNames = 0;

class Configurator extends Component {
  state = {
    values: model,
    touched: {},
    errors: {},
    submitted: false,
    
    summary: false,     //display data
    modified: false,    //out of dated data
    selected: 1,    //number of selected gearbox in summary
    gearbox: null,  //selected gearbox
    showNotes: false,   //hide/display notes

    warningHss: false,
    warningRatio: false,
    warningLst: false,
    warningEmail: false,

    negativeAngle1: 0,
    transformedAngle1: -180,
    negativeAngle2: 0,  
    transformedAngle2: -180,
    negativeAngle3: 0,
    transformedAngle3: -180,
    negativeAngle4: 0,
    transformedAngle4: -180,
    previousMove :{
        x: 0,
        y: 0
    },
    buttonPressed: false,

    stop: false,    //stop if a major condition is not met
    quoteStop: false,   //stop if the form is not correctly filled
    loading: false, //page is loading
    isSubmitted: false,  //after RQ being sent
    isContactUsSubmitted: false,
    isWarning1Shown: false, //ERR1 Self-cooled
    isWarning2Shown: false,  //ERR2 KAREQ < 3KA
    isWarning3Shown: false,  //ERR3 MMAX*KAREQ > 400
    shaftSelection: false,  //shaft selection dialog
    externalCooling: true, //is externally cooled
    externalForces: false,
    accessories: false,

    //lists
    listStartsPerHour: 0,
    listPrimeMover: 0,
    listStaticPeaksPerHour: 0,
    listDutyCycle: 0,
    listAmbientTemperature: 0,
    listUtilization: 0,
    listAirFlow: 0,

    //quotation form
    email: '',
    phone: '',
    message: '',

    schemaUrl: '',
    modelUrl: '',

    names: ['A', 'B', 'C', 'D'],    
    
    industry_options: [],
    application_options: [],
    drivenMachine_options: [],
    operatingPeriod_options: [],
    climate_options: [],
    positioning_options: [],
    lubrication_options: [],
    peaksperhour_options: [],
    primemover_options: [],
    elevation_options: [],
    highspeedshaft_options: [],
    lowspeedshaft_options: [],
    lowspeedshaftHollow_options: [],
    highspeedCoupling_options: [],
    lowspeedCoupling_options: [],
    dutycycle_options: [],
    ambienttemperature_options: [],
    utilization_options: [],
    airflow_options: []
  }; 
  
  componentWillMount() {
    fetch(config.server + '/api/access?destination=wingear', {
        headers: new Headers({
        "Authorization": basic
        }),
    })
    .then(() => {});
  }

  componentDidMount() {
    fetch(config.cache + '/api/wingear/industries.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
    }).then(result => {
        let options = result.map((opt => {
            return (
                <option value={opt.id} key={opt.id}>{opt.name}</option>
            )
        })
        )
        this.setState({ industry_options: options });
    });    

    fetch(config.cache + '/api/wingear/applications.json', {
      headers: new Headers({
        "Authorization": basic
      }),
    })
    .then(response => {
        return response.json();
    }).then(result => {
        let options = result.map((opt => {
            return (
                <option value={opt.id} key={opt.id}>{opt.name}</option>
            )
        })
        )
        this.setState({ application_options: options });
    });    

    fetch(config.cache + '/api/wingear/climates.json', {
      headers: new Headers({
        "Authorization": basic
      }),
    })
    .then(response => {
        return response.json();
    }).then(result => {
        let options = result.map((opt => {
            return (
                <option value={opt.id} key={opt.id}>{opt.name}</option>
            )
        })
        )
        this.setState({ climate_options: options });
    });

    fetch(config.cache + '/api/wingear/gearboxpositionings.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.code}>{opt.name}</option>
              )
          })
          )
          this.setState({ positioning_options: options });
      });
  
      fetch(config.cache + '/api/lubrications.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.id} key={opt.id}>{opt.name}</option>
              )
          })
          )
          this.setState({ lubrication_options: options });
      });
      
      fetch(config.cache + '/api/wingear/mountingarrangements.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.id} key={opt.id}>{opt.name}</option>
              )
          })
          )
          this.setState({ arrangement_options: options });
      });
      
      fetch(config.cache + '/api/wingear/lowspeedshafts.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.id} key={opt.id}>{opt.name}</option>
              )
          })
          )
          this.setState({ lowspeedshaft_options: options.splice(0, 3) });
          this.setState({ lowspeedshaftHollow_options : options.splice(-2, 2) });
      });
      
      fetch(config.cache + '/api/wingear/highspeedshafts.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.id} key={opt.id}>{opt.name}</option>
              )
          })
          )
          this.setState({ highspeedshaft_options: options });
      });
      
      fetch(config.cache + '/api/wingear/peaksperhour.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.paramCp} key={opt.paramCp}>{opt.name}</option>
              )
          })
          )
          this.setState({ peaksperhour_options: options });
      });
      
      fetch(config.cache + '/api/wingear/drivenmachineptfs.json', {
        headers: new Headers({
          "Authorization": basic
        }),
      })
      .then(response => {
          return response.json();
      }).then(result => {
          let options = result.map((opt => {
              return (
                  <option value={opt.ptf} key={opt.ptf}>{opt.name}</option>
              )
          })
          )
          this.setState({ primemover_options: options})
      })
      
      fetch(config.cache + '/api/wingear/elevations.json', {
          headers: new Headers({
            "Authorization": basic
          }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            let options = result.map((opt => {
                return (
                    <option value={opt.id} key={opt.id}>{opt.name}</option>
                )
            })
            )
            this.setState({ elevation_options: options})
        })
        
        fetch(config.cache + '/api/wingear/highspeedcouplings.json', {
          headers: new Headers({
            "Authorization": basic
          }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            let options = result.map((opt => {
                return (
                    <option value={opt.id} key={opt.id}>{opt.name}</option>
                )
            })
            )
            this.setState({ highspeedCoupling_options: options})
        })
  
        fetch(config.cache + '/api/wingear/lowspeedcouplings.json', {
          headers: new Headers({
            "Authorization": basic
          }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            let options = result.map((opt => {
                return (
                    <option value={opt.id} key={opt.id}>{opt.name}</option>
                )
            })
            )
            this.setState({ lowspeedCoupling_options: options})
        })

        fetch(config.cache + '/api/wingear/dutycycles.json', {
            headers: new Headers({
              "Authorization": basic
            }),
          })
          .then(response => {
              return response.json();
          }).then(result => {
              let options = result.map((opt => {
                  return (
                      <option value={opt.cycle} key={opt.id}>{opt.cycle}</option>
                  )
              })
              )
              this.setState({ dutycycle_options: options})
          })

        fetch(config.cache + '/api/wingear/ambienttemperatures.json', {
            headers: new Headers({
                "Authorization": basic
            }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            let options = result.map((opt => {
                return (
                    <option value={opt.temperature} key={opt.id}>{opt.temperature}</option>
                )
            })
            )
            this.setState({ ambienttemperature_options: options})
        })

        fetch(config.cache + '/api/wingear/utilizations.json', {
            headers: new Headers({
                "Authorization": basic
            }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            let options = result.map((opt => {
                return (
                    <option value={opt.utilization} key={opt.id}>{opt.utilization}</option>
                )
            })
            )
            this.setState({ utilization_options: options})
        })

        fetch(config.cache + '/api/wingear/airflows.json', {
            headers: new Headers({
                "Authorization": basic
            }),
        })
        .then(response => {
            return response.json();
        }).then(result => {
            let options = result.map((opt => {
                return (
                    <option value={opt.fv} key={opt.id}>{opt.selection}</option>
                )
            })
            )
            this.setState({ airflow_options: options})
        })
  }

  handleSubmit = values => {
    this.setState({ loading : true });
    indexNames = 0;
    fetch(config.server + '/wingear/api/gearbox/request', {
      method: 'POST',
      headers: {
        "Authorization": basic,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "params": {
          "type": model.type,
          "shaftType": (model.type === 'helical') ? model.shaftTypeHelical : model.shaftTypeBevel,
          "lss": model.gearboxParams.lss,
          "hss": model.gearboxParams.hss,
          "lst": model.gearboxParams.lst * model.unitslst.knm,
          "hst": model.gearboxParams.hst * model.unitshst.knm,
          "pwr": model.gearboxParams.pwr * model.unitspwr.kw,
          "ratio": model.gearboxParams.ratio,
          "ka": model.ka,
          "mstatic": model.mstatic * model.unitsmst.knm,
          "cplss": model.cplss,
          "cphss": model.cphss,
          "cs": model.cs,
          "application": model.application,
          "industry": model.industry,
          "effectivePeriod": model.effectiveDaily.selected,
          "dutyCycle": model.dutycycle,
          "ambientTemperature": model.ambienttemperature,
          "utilization": model.utilization,
          "airFlow": model.airflow,
          "highspeedPosition": (model.type === 'helical') ? model.shaftTypeHelical.substring(1, 2) : model.shaftTypeBevel.substring(1, 2),
          "lowspeedPosition": (model.type === 'helical') ? model.shaftTypeHelical.substring(2, 3) : model.shaftTypeBevel.substring(2, 3),
          "externalCooling": this.state.externalCooling
        }
      })
    })
    .then(response => {
      return response.json();            
    })
    .then((data) => {
        model.gearboxes.optimal.stages = data.gearboxOptimal.stages;
        model.gearboxes.optimal.size = data.gearboxOptimal.size;
        model.gearboxes.optimal.trueRatio = data.gearboxOptimal.trueRatio;
        model.gearboxes.optimal.nominalRatio = data.gearboxOptimal.nominalRatio;
        model.gearboxes.optimal.deviation = data.gearboxOptimal.deviation;
        model.gearboxes.optimal.ka = data.gearboxOptimal.ratedTorque / this.getTrueLowspeedTorque(data.gearboxOptimal.trueRatio);
        model.gearboxes.optimal.ratedTorque = data.gearboxOptimal.ratedTorque;
        model.gearboxes.optimal.lss = data.gearboxOptimal.lss;
        model.gearboxes.optimal.bearingLife = data.gearboxOptimal.bearingLife;
        model.gearboxes.optimal.coolingFactor = data.gearboxOptimal.coolingFactor;
        model.gearboxes.optimal.weight = data.gearboxOptimal.weight;   console.log(model.gearboxes.optimal.weight);

        if(data.gearboxOptimalSecond.size !== null) {
            model.gearboxes.optimalSecond.stages = data.gearboxOptimalSecond.stages;
            model.gearboxes.optimalSecond.size = data.gearboxOptimalSecond.size;
            model.gearboxes.optimalSecond.trueRatio = data.gearboxOptimalSecond.trueRatio;
            model.gearboxes.optimalSecond.nominalRatio = data.gearboxOptimalSecond.nominalRatio;
            model.gearboxes.optimalSecond.deviation = data.gearboxOptimalSecond.deviation;
            model.gearboxes.optimalSecond.ka = data.gearboxOptimalSecond.ratedTorque / this.getTrueLowspeedTorque(data.gearboxOptimalSecond.trueRatio);
            model.gearboxes.optimalSecond.ratedTorque = data.gearboxOptimalSecond.ratedTorque;
            model.gearboxes.optimalSecond.lss = data.gearboxOptimalSecond.lss;
            model.gearboxes.optimalSecond.bearingLife = data.gearboxOptimalSecond.bearingLife;
            model.gearboxes.optimalSecond.coolingFactor = data.gearboxOptimalSecond.coolingFactor;
            model.gearboxes.optimalSecond.weight = data.gearboxOptimalSecond.weight;
        }
        else {
            model.gearboxes.optimalSecond.stages = 0;
            model.gearboxes.optimalSecond.size = 0;
            model.gearboxes.optimalSecond.trueRatio = 0;
            model.gearboxes.optimalSecond.nominalRatio = 0;
            model.gearboxes.optimalSecond.deviation = 0;
            model.gearboxes.optimalSecond.ka = 0;
            model.gearboxes.optimalSecond.ratedTorque = 0;
            model.gearboxes.optimalSecond.lss = 0;
            model.gearboxes.optimalSecond.bearingLife = 0;
            model.gearboxes.optimalSecond.coolingFactor = 0; 
            model.gearboxes.optimalSecond.weight = 0;
        }

        if(data.gearboxSuboptimal.size !== null) {
            model.gearboxes.suboptimal.stages = data.gearboxSuboptimal.stages;
            model.gearboxes.suboptimal.size = data.gearboxSuboptimal.size;
            model.gearboxes.suboptimal.trueRatio = data.gearboxSuboptimal.trueRatio;
            model.gearboxes.suboptimal.nominalRatio = data.gearboxSuboptimal.nominalRatio;
            model.gearboxes.suboptimal.deviation = data.gearboxSuboptimal.deviation;
            model.gearboxes.suboptimal.ka = data.gearboxSuboptimal.ratedTorque / this.getTrueLowspeedTorque(data.gearboxSuboptimal.trueRatio);
            model.gearboxes.suboptimal.ratedTorque = data.gearboxSuboptimal.ratedTorque;
            model.gearboxes.suboptimal.lss = data.gearboxSuboptimal.lss;
            model.gearboxes.suboptimal.bearingLife = data.gearboxSuboptimal.bearingLife;
            model.gearboxes.suboptimal.coolingFactor = data.gearboxSuboptimal.coolingFactor;
            model.gearboxes.suboptimal.weight = data.gearboxSuboptimal.weight;
        }
        else {
            model.gearboxes.suboptimal.stages = 0;
            model.gearboxes.suboptimal.size = 0;
            model.gearboxes.suboptimal.trueRatio = 0;
            model.gearboxes.suboptimal.nominalRatio = 0;
            model.gearboxes.suboptimal.deviation = 0;
            model.gearboxes.suboptimal.ka = 0;
            model.gearboxes.suboptimal.ratedTorque = 0;
            model.gearboxes.suboptimal.lss = 0;
            model.gearboxes.suboptimal.bearingLife = 0;
            model.gearboxes.suboptimal.coolingFactor = 0;
            model.gearboxes.suboptimal.weight = 0;
        }

        if(data.gearboxSuboptimalSecond.size !== null) {
            model.gearboxes.suboptimalSecond.stages = data.gearboxSuboptimalSecond.stages;
            model.gearboxes.suboptimalSecond.size = data.gearboxSuboptimalSecond.size;
            model.gearboxes.suboptimalSecond.trueRatio = data.gearboxSuboptimalSecond.trueRatio;
            model.gearboxes.suboptimalSecond.nominalRatio = data.gearboxSuboptimalSecond.nominalRatio;
            model.gearboxes.suboptimalSecond.deviation = data.gearboxSuboptimalSecond.deviation;
            model.gearboxes.suboptimalSecond.ka = data.gearboxSuboptimalSecond.ratedTorque / this.getTrueLowspeedTorque(data.gearboxSuboptimalSecond.trueRatio);
            model.gearboxes.suboptimalSecond.ratedTorque = data.gearboxSuboptimalSecond.ratedTorque;
            model.gearboxes.suboptimalSecond.lss = data.gearboxSuboptimalSecond.lss;
            model.gearboxes.suboptimalSecond.bearingLife = data.gearboxSuboptimalSecond.bearingLife;
            model.gearboxes.suboptimalSecond.coolingFactor = data.gearboxSuboptimalSecond.coolingFactor;
            model.gearboxes.suboptimalSecond.weight = data.gearboxSuboptimalSecond.weight;
        }
        else {
            model.gearboxes.suboptimalSecond.stages = 0;
            model.gearboxes.suboptimalSecond.size = 0;
            model.gearboxes.suboptimalSecond.trueRatio = 0;
            model.gearboxes.suboptimalSecond.nominalRatio = 0;
            model.gearboxes.suboptimalSecond.deviation = 0;
            model.gearboxes.suboptimalSecond.ka = 0;
            model.gearboxes.suboptimalSecond.ratedTorque = 0;
            model.gearboxes.suboptimalSecond.lss = 0;
            model.gearboxes.suboptimalSecond.bearingLife = 0;
            model.gearboxes.suboptimalSecond.coolingFactor = 0;
            model.gearboxes.suboptimalSecond.weight = 0;
        }        

        this.setState({
            modified: false,
            summary: true,
            selected: 1,
            gearbox: model.gearboxes.optimal,
            schemaUrl: this.getSchemaUrl(model.gearboxes.optimal),
            modelUrl: this.getModelUrl(model.gearboxes.optimal),
            loading : false
        })        

        if (data.error === 'ERR1') {
            this.setState({isWarning1Shown: true, summary: false});
        } else if (data.error === 'ERR2') {
            this.setState({isWarning2Shown: true});
        } else if (data.error === 'ERR3') {
            this.setState({isWarning3Shown: true, summary: false});
        }
    })
  };

  handleRequest = () => {
    this.setState({ loading : true });

    let lowspeedShaftTorque;
    let power;

    if (model.fixedParam === 'pwr' || model.fixedParam === 'hst') {
        power = parseFloat(model.gearboxParams.pwr).toFixed(2);
    }
    else if (model.fixedParam === 'lst') {
        power = parseFloat((2 * 3.141592 * (model.gearboxParams.lst / this.state.gearbox.trueRatio) * model.gearboxParams.hss) / 60).toFixed(2);
    }

    if (model.fixedParam === 'pwr') {
        if (parseInt(model.unitslst.current) === 1) {
            lowspeedShaftTorque = parseFloat(((60 * model.gearboxParams.pwr * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)) * 1000.0).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) {
            lowspeedShaftTorque = parseFloat((60 * model.gearboxParams.pwr * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)).toFixed(2);
        } else {
            lowspeedShaftTorque = parseFloat(((60 * model.gearboxParams.pwr * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)) * 737.562).toFixed(2);
        }
    }
    else if (model.fixedParam === 'lst') {
        if (parseInt(model.unitslst.current) === 1) {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.lst * model.unitslst.nm).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.lst * model.unitslst.knm).toFixed(2); 
        } else {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.lst * model.unitslst.ftlb).toFixed(2); 
        }
    }
    else if (model.fixedParam === 'hst') {
        if (parseInt(model.unitslst.current) === 1) { 
            lowspeedShaftTorque = parseFloat(model.gearboxParams.hst * this.state.gearbox.trueRatio * 1000.0).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) { 
            lowspeedShaftTorque = parseFloat(model.gearboxParams.hst * this.state.gearbox.trueRatio).toFixed(2);
        } else {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.hst * this.state.gearbox.trueRatio * 737.562).toFixed(2);
        }
    }

    fetch(config.server + '/wingear/api/configuration/add', {
        method: 'POST',
        headers: {
          "Authorization": basic,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "type": model.type.substring(0, 1).toUpperCase(),
          "highspeedPosition": (model.type === 'helical') ? model.shaftTypeHelical.substring(1, 2) : model.shaftTypeBevel.substring(1, 2),
          "lowspeedPosition": (model.type === 'helical') ? model.shaftTypeHelical.substring(2, 3) : model.shaftTypeBevel.substring(2, 3),
          "hss": model.gearboxParams.hss,
          "lss": model.gearboxParams.lss,
          "ratio": model.gearboxParams.ratio,
          "pwr": model.gearboxParams.pwr,
          "unitPwr": (parseInt(model.unitspwr.current) === 1) ? "kW" : "hp",
          "hst": model.gearboxParams.hst,
          "unitHst": (parseInt(model.unitshst.current) === 1) ? "Nm" : (parseInt(model.unitshst.current) === 2) ? "kNm" : "lb-ft",
          "lst": model.gearboxParams.lst,
          "unitLst": (parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft",
          "industry": model.industry,
          "application": model.application,
          "dailyPeriod": model.effectiveDaily.selected,
          "ka": model.ka,
          "startsPerHour": model.cphss,
          "primeMover": model.cs,
          "staticPeaks": model.cplss,
          "staticTorque": model.mstatic,
          "unitTorque": (parseInt(model.unitsmst.current) === 1) ? "Nm" : (parseInt(model.unitsmst.current) === 2) ? "kNm" : "lb-ft",
          "angle1": model.angle1,
          "angle2": model.angle2,
          "bearingLife": parseInt(this.getBearingLife(parseFloat(this.state.gearbox.bearingLife).toFixed(0))),
          "mountingArrangement": model.mountingArrangement,
          "highspeedShaft": model.highspeedShaft,
          "lowspeedShaft": model.lowspeedShaft,
          "dusty": model.dusty,
          "explosive": model.explosive,
          "climate": model.climate,
          "elevation": model.elevation,
          "gearboxSize": parseInt(this.state.gearbox.size),
          "gearboxRatio": this.state.gearbox.trueRatio,
          "coolingFactor": this.state.gearbox.coolingFactor,
          "externalForces": model.externalForces,
          "hssRadial": model.hssRadial,
          "hssRadialUnits": model.hssRadialUnits,
          "angle3": model.angle3,
          "lssRadial": model.lssRadial,
          "lssRadialUnits": model.lssRadialUnits,
          "angle4": model.angle4,
          "hssAxial": model.hssAxial,
          "hssAxialUnits": model.hssAxialUnits,
          "hssAxialIn": model.hssAxialIn,
          "lssAxial": model.lssAxial,
          "lssAxialUnits": model.lssAxialUnits,
          "lssAxialIn": model.lssAxialIn,
          "lubricationUnit": model.lubricationUnit,
          "cooledByWater": model.coolingByWater,
          "highspeedCoupling": model.highspeedCoupling,
          "highspeedCouplingType": model.highspeedCouplingType,
          "lowspeedCoupling": model.lowspeedCoupling,
          "lowspeedCouplingType": model.lowspeedCouplingType,
          "backStop": model.backStop,
          "safetyBrake": model.safetyBrake,
          "electricMotor": model.electricMotor,
          "frequencyConvertor": model.frequencyConvertor,
          "flangeMotor": model.flangeMotor,
          "outputFlange": model.outputFlange,
          "inputCooling": model.inputCooling,
          "torqueSupport": model.torqueSupport,
          "notes": model.notes
        })
    })
    .then((response) => {
        return response.text();
    })
    .then(text => {
        fetch(config.server + '/wingear/api/customer/add', {
            method: 'POST',
            headers: {
              "Authorization": basic,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({                
                "customer" : {
                    "email": this.state.email,
                    "phone": this.state.phone,
                    "message": this.state.message,
                    "configuration": text,
                },
                "pdf": {
                    "stages": this.state.gearbox.stages,
                    "type": model.type.substring(0, 1).toUpperCase(),
                    "shaftType": (model.type === 'helical') ? model.shaftTypeHelical : model.shaftTypeBevel,
                    "ratedTorque": (parseInt(model.unitslst.current) === 1) ? parseFloat(this.state.gearbox.ratedTorque * 1000.0).toFixed(2) : (parseInt(model.unitslst.current) === 2) ? parseFloat(this.state.gearbox.ratedTorque).toFixed(2) : parseFloat(this.state.gearbox.ratedTorque * 737.562).toFixed(2),
                    "ratedTorqueUnits": (parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft" ,
                    "hss": model.gearboxParams.hss,
                    "lss": this.state.gearbox.lss,
                    "ratio": model.gearboxParams.ratio,
                    "pwr": power,
                    "unitPwr": (parseInt(model.unitspwr.current) === 1) ? "kW" : "hp",
                    "hst": model.gearboxParams.hst,
                    "unitHst": (parseInt(model.unitshst.current) === 1) ? "Nm" : (parseInt(model.unitshst.current) === 2) ? "kNm" : "lb-ft",
                    "lst": lowspeedShaftTorque,
                    "unitLst": (parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft",
                    "industry": model.industry,
                    "application": model.application,
                    "dailyPeriod": model.effectiveDaily.selected,
                    "ka": model.ka,
                    "kaCalculated": this.state.gearbox.ka,
                    "startsPerHour": this.state.listStartsPerHour,
                    "primeMover": this.state.listPrimeMover,
                    "staticPeaks": this.state.listStaticPeaksPerHour,
                    "staticTorque": model.mstatic,
                    "unitTorque": (parseInt(model.unitsmst.current) === 1) ? "Nm" : (parseInt(model.unitsmst.current) === 2) ? "kNm" : "lb-ft",
                    "angle1": model.angle1,
                    "angle2": model.angle2,
                    "bearingLife": Math.round(parseFloat(this.state.gearbox.bearingLife).toFixed(0) / 100) * 100,
                    "mountingArrangement": model.mountingArrangement,
                    "highspeedShaft": model.highspeedShaft,
                    "lowspeedShaft": model.lowspeedShaft,
                    "lubrication": model.lubrication,
                    "dusty": model.dusty,
                    "explosive": model.explosive,
                    "offshore": model.offshore,
                    "climate": model.climate,
                    "elevation": model.elevation,
                    "gearboxSize": parseInt(this.state.gearbox.size),
                    "gearboxTrueRatio": this.state.gearbox.trueRatio,
                    "gearboxNominalRatio": this.state.gearbox.nominalRatio,
                    "deviation": this.state.gearbox.deviation,
                    "lubricationUnit": model.lubricationUnit,
                    "highspeedCoupling": model.highspeedCoupling,
                    "highspeedCouplingType": model.highspeedCouplingType,
                    "lowspeedCoupling": model.lowspeedCoupling,
                    "lowspeedCouplingType": model.lowspeedCouplingType,
                    "backStop": model.backStop,
                    "safetyBrake": model.safetyBrake,
                    "electricMotor": model.electricMotor,
                    "frequencyConvertor": model.frequencyConvertor,
                    "flangeElectric": model.flangeMotor,
                    "outputFlange": model.outputFlange,
                    "inputCooling": model.inputCooling,
                    "torqueSupport": model.torqueSupport,
                    "notes": model.notes,
                    "cooling": (this.state.externalCooling) ? 'E' : 'S',
                    "dutyCycle": model.dutycycle,
                    "ambientTemperature": model.ambienttemperature,
                    "utilization": model.utilization,
                    "airSpeed": this.state.listAirFlow,
                    "coolingFactor": this.state.gearbox.coolingFactor,
                    "hssRadial": model.hssRadial,
                    "hssRadialUnits": model.hssRadialUnits,
                    "angle3": model.angle3,
                    "lssRadial": model.lssRadial,
                    "lssRadialUnits": model.lssRadialUnits,
                    "angle4": model.angle4,
                    "hssAxial": model.hssAxial,
                    "hssAxialUnits": model.hssAxialUnits,
                    "hssAxialIn": model.hssAxialIn,
                    "lssAxial": model.lssAxial,
                    "lssAxialUnits": model.lssAxialUnits,
                    "lssAxialIn": model.lssAxialIn,
                    "coolingByWater": model.coolingByWater,
                    "externalForces": this.state.externalForces,
                    "externalCooling": this.state.externalCooling
                }
            })
        })
        .then(response => {
            return response;            
        })
        .then(() => {
            this.setState({      
                loading : false,
                isSubmitted: true
            })
        })            
    })    
  }

  handleContact = () => {
    this.setState({ loading : true });

    fetch(config.server + '/wingear/api/configuration/add', {
        method: 'POST',
        headers: {
          "Authorization": basic,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "type": model.type.substring(0, 1).toUpperCase(),
            "highspeedPosition": (model.type === 'helical') ? model.shaftTypeHelical.substring(1, 2) : model.shaftTypeBevel.substring(1, 2),
            "lowspeedPosition": (model.type === 'helical') ? model.shaftTypeHelical.substring(2, 3) : model.shaftTypeBevel.substring(2, 3),
            "hss": model.gearboxParams.hss,
            "lss": model.gearboxParams.lss,
            "ratio": model.gearboxParams.ratio,
            "pwr": model.gearboxParams.pwr,
            "unitPwr": (parseInt(model.unitspwr.current) === 1) ? "kW" : "hp",
            "hst": model.gearboxParams.hst,
            "unitHst": (parseInt(model.unitshst.current) === 1) ? "Nm" : (parseInt(model.unitshst.current) === 2) ? "kNm" : "lb-ft",
            "lst": model.gearboxParams.lst,
            "unitLst": (parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft",
            "industry": model.industry,
            "application": model.application,
            "dailyPeriod": model.effectiveDaily.selected,
            "ka": model.ka,
            "startsPerHour": model.cphss,
            "primeMover": model.cs,
            "staticPeaks": model.cplss,
            "staticTorque": model.mstatic,
            "unitTorque": (parseInt(model.unitsmst.current) === 1) ? "Nm" : (parseInt(model.unitsmst.current) === 2) ? "kNm" : "lb-ft",
            "angle1": model.angle1,
            "angle2": model.angle2,
            "bearingLife": (this.state.gearbox != null) ? parseInt(this.getBearingLife(parseFloat(this.state.gearbox.bearingLife).toFixed(0))) : 0,
            "mountingArrangement": model.mountingArrangement,
            "highspeedShaft": model.highspeedShaft,
            "lowspeedShaft": model.lowspeedShaft,
            "dusty": model.dusty,
            "explosive": model.explosive,
            "climate": model.climate,
            "elevation": model.elevation,
            "gearboxSize": (this.state.gearbox != null) ? parseInt(this.state.gearbox.size) : 0,
            "gearboxRatio": (this.state.gearbox != null) ? this.state.gearbox.trueRatio : 0,
            "coolingFactor": (this.state.gearbox != null) ? this.state.gearbox.coolingFactor : 0,
            "externalForces": model.externalForces,
            "hssRadial": model.hssRadial,
            "hssRadialUnits": model.hssRadialUnits,
            "angle3": model.angle3,
            "lssRadial": model.lssRadial,
            "lssRadialUnits": model.lssRadialUnits,
            "angle4": model.angle4,
            "hssAxial": model.hssAxial,
            "hssAxialUnits": model.hssAxialUnits,
            "hssAxialIn": model.hssAxialIn,
            "lssAxial": model.lssAxial,
            "lssAxialUnits": model.lssAxialUnits,
            "lssAxialIn": model.lssAxialIn,
            "lubricationUnit": model.lubricationUnit,
            "cooledByWater": model.coolingByWater,
            "highspeedCoupling": model.highspeedCoupling,
            "highspeedCouplingType": model.highspeedCouplingType,
            "lowspeedCoupling": model.lowspeedCoupling,
            "lowspeedCouplingType": model.lowspeedCouplingType,
            "backStop": model.backStop,
            "safetyBrake": model.safetyBrake,
            "electricMotor": model.electricMotor,
            "frequencyConvertor": model.frequencyConvertor,
            "flangeMotor": model.flangeMotor,
            "outputFlange": model.outputFlange,
            "inputCooling": model.inputCooling,
            "torqueSupport": model.torqueSupport,
            "notes": model.notes
        })
    })
    .then((response) => {
        return response.text();
    })
    .then(text => {
        fetch(config.server + '/wingear/api/customer/add', {
            method: 'POST',
            headers: {
              "Authorization": basic,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "customer": {
                    "email": this.state.email,
                    "phone": this.state.phone,
                    "message": this.state.message,
                    "configuration": text
                },
                "pdf": null                
            })
        })
        .then(response => {
            return response;            
        })
        .then(() => {
            this.setState({      
                loading : false,
                isContactUsSubmitted: true
            })                
        })            
    })    
  }

  handlePdf = () => {
    this.setState({ loading : true });

    let lowspeedShaftTorque;
    let power;

    if (model.fixedParam === 'pwr' || model.fixedParam === 'hst') {
        power = parseFloat(model.gearboxParams.pwr).toFixed(2);
    }
    else if (model.fixedParam === 'lst') {
        power = parseFloat((2 * 3.141592 * (model.gearboxParams.lst / this.state.gearbox.trueRatio) * model.gearboxParams.hss) / 60).toFixed(2);
    }

    if (model.fixedParam === 'pwr') {
        if (parseInt(model.unitslst.current) === 1) {                                  
            lowspeedShaftTorque = parseFloat(((60 * model.gearboxParams.pwr * model.unitspwr.kw * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)) * 1000.0).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) {
            lowspeedShaftTorque = parseFloat((60 * model.gearboxParams.pwr * model.unitspwr.kw * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)).toFixed(2);
        } else {
            lowspeedShaftTorque = parseFloat(((60 * model.gearboxParams.pwr * model.unitspwr.kw * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)) * 737.562).toFixed(2);
        }
    }
    else if (model.fixedParam === 'lst') {
        if (parseInt(model.unitslst.current) === 1) {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.lst * model.unitslst.nm).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.lst * model.unitslst.knm).toFixed(2); 
        } else {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.lst * model.unitslst.ftlb).toFixed(2); 
        }
    }
    else if (model.fixedParam === 'hst') {
        if (parseInt(model.unitslst.current) === 1) { 
            lowspeedShaftTorque = parseFloat(model.gearboxParams.hst * this.state.gearbox.trueRatio * 1000.0).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) { 
            lowspeedShaftTorque = parseFloat(model.gearboxParams.hst * this.state.gearbox.trueRatio).toFixed(2);
        } else {
            lowspeedShaftTorque = parseFloat(model.gearboxParams.hst * this.state.gearbox.trueRatio * 737.562).toFixed(2);
        }
    }

    axios(`${config.server}/wingear/api/pdf`, {
      method: 'POST',
      responseType: 'blob',
      headers: {
        "Authorization": basic,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
          "stages": this.state.gearbox.stages,
          "type": model.type.substring(0, 1).toUpperCase(),
          "shaftType": (model.type === 'helical') ? model.shaftTypeHelical : model.shaftTypeBevel,
          "ratedTorque": (parseInt(model.unitslst.current) === 1) ? parseFloat(this.state.gearbox.ratedTorque * 1000.0).toFixed(2) : (parseInt(model.unitslst.current) === 2) ? parseFloat(this.state.gearbox.ratedTorque).toFixed(2) : parseFloat(this.state.gearbox.ratedTorque * 737.562).toFixed(2),
          "ratedTorqueUnits": (parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft" ,
          "hss": model.gearboxParams.hss,
          "lss": this.state.gearbox.lss,
          "ratio": model.gearboxParams.ratio,
          "pwr": power,
          "unitPwr": (parseInt(model.unitspwr.current) === 1) ? "kW" : "hp",
          "hst": model.gearboxParams.hst,
          "unitHst": (parseInt(model.unitshst.current) === 1) ? "Nm" : (parseInt(model.unitshst.current) === 2) ? "kNm" : "lb-ft",
          "lst": lowspeedShaftTorque,
          "unitLst": (parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft",
          "industry": model.industry,
          "application": model.application,
          "dailyPeriod": model.effectiveDaily.selected,
          "ka": model.ka,
          "kaCalculated": this.state.gearbox.ka,
          "startsPerHour": this.state.listStartsPerHour,
          "primeMover": this.state.listPrimeMover,
          "staticPeaks": this.state.listStaticPeaksPerHour,
          "staticTorque": model.mstatic,
          "unitTorque": (parseInt(model.unitsmst.current) === 1) ? "Nm" : (parseInt(model.unitsmst.current) === 2) ? "kNm" : "lb-ft",
          "angle1": model.angle1,
          "angle2": model.angle2,
          "bearingLife": Math.round(parseFloat(this.state.gearbox.bearingLife).toFixed(0) / 100) * 100,
          "mountingArrangement": model.mountingArrangement,
          "highspeedShaft": model.highspeedShaft,
          "lowspeedShaft": model.lowspeedShaft,
          "lubrication": model.lubrication,
          "dusty": model.dusty,
          "explosive": model.explosive,
          "offshore": model.offshore,
          "climate": model.climate,
          "elevation": model.elevation,
          "gearboxSize": parseInt(this.state.gearbox.size),
          "gearboxTrueRatio": this.state.gearbox.trueRatio,
          "gearboxNominalRatio": this.state.gearbox.nominalRatio,
          "deviation": this.state.gearbox.deviation,
          "lubricationUnit": model.lubricationUnit,
          "highspeedCoupling": model.highspeedCoupling,
          "highspeedCouplingType": model.highspeedCouplingType,
          "lowspeedCoupling": model.lowspeedCoupling,
          "lowspeedCouplingType": model.lowspeedCouplingType,
          "backStop": model.backStop,
          "safetyBrake": model.safetyBrake,
          "electricMotor": model.electricMotor,
          "frequencyConvertor": model.frequencyConvertor,
          "flangeElectric": model.flangeMotor,
          "outputFlange": model.outputFlange,
          "inputCooling": model.inputCooling,
          "torqueSupport": model.torqueSupport,
          "notes": model.notes,
          "cooling": (this.state.externalCooling) ? 'E' : 'S',
          "dutyCycle": model.dutycycle,
          "ambientTemperature": model.ambienttemperature,
          "utilization": model.utilization,
          "airSpeed": this.state.listAirFlow,
          "coolingFactor": this.state.gearbox.coolingFactor,
          "hssRadial": model.hssRadial,
          "hssRadialUnits": model.hssRadialUnits,
          "angle3": model.angle3,
          "lssRadial": model.lssRadial,
          "lssRadialUnits": model.lssRadialUnits,
          "angle4": model.angle4,
          "hssAxial": model.hssAxial,
          "hssAxialUnits": model.hssAxialUnits,
          "hssAxialIn": model.hssAxialIn,
          "lssAxial": model.lssAxial,
          "lssAxialUnits": model.lssAxialUnits,
          "lssAxialIn": model.lssAxialIn,
          "coolingByWater": model.coolingByWater,
          "externalForces": this.state.externalForces,
          "externalCooling": this.state.externalCooling
      }),
    })    
    .then(response => {
      var newBlob = new Blob([response.data], {type: "application/pdf"})
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, "Gearbox.pdf");
        this.setState({ loading : false });
        return;
      } 

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download="Gearbox.pdf";
      link.click();
      setTimeout(function(){
        window.URL.revokeObjectURL(data);
      }, 1000);
      
      this.setState({ loading : false });
    })
    .catch(error => {
        console.log(error);
    })
  }

  getTrueLowspeedTorque(trueRatio) {
    let lstTorque = 0;
    if (model.fixedParam === 'pwr') {
        console.log(model.unitspwr.current)
        if (parseInt(model.unitspwr.current) === 1) {
            lstTorque = parseFloat((60 * model.gearboxParams.pwr * trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)).toFixed(2);
        } else {
            lstTorque = parseFloat((60 * (model.gearboxParams.pwr / 1.34102) * trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)).toFixed(2);
        }
        console.log('lst ' + lstTorque)
    }
    else if (model.fixedParam === 'lst') {
        if (parseInt(model.unitslst.current) === 1) {
            lstTorque = parseFloat(model.gearboxParams.lst * model.unitslst.knm).toFixed(2);
        } else if (parseInt(model.unitslst.current) === 2) {
            lstTorque = parseFloat(model.gearboxParams.lst).toFixed(2); 
        } else {
            lstTorque = parseFloat(model.gearboxParams.lst * model.unitslst.knm).toFixed(2); 
        }
    }
    else if (model.fixedParam === 'hst') {
        console.log('hst curr ' + model.unitshst.current)
        console.log(model.gearboxParams.hst)
        console.log(model.unitshst.knm)
        if (parseInt(model.unitshst.current) === 1) { 
            lstTorque = parseFloat((model.gearboxParams.hst * model.unitshst.knm) * trueRatio).toFixed(2);
        } else if (parseInt(model.unitshst.current) === 2) { 
            lstTorque = parseFloat((model.gearboxParams.hst) * trueRatio).toFixed(2);
        } else {
            lstTorque = parseFloat((model.gearboxParams.hst * model.unitshst.knm) * trueRatio).toFixed(2);
        }
    }
    return lstTorque;
  }

  filterText(text) {
      return text
        .replace(/</g, '')
        .replace(/>/g, '')
        .replace(/\{/g, '')
        .replace(/\}/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/=/g, '')
        .replace(/;/g, '')
        .replace(/\*/g, '');
  }

  formatNumber(number) {
      console.log(number)
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getSchemaUrl(gearbox) {
      let detail = '';
      if (model.type === 'helical') {
          if (model.shaftTypeHelical.substring(2, 3) === 'H') {
              if (parseInt(model.lowspeedShaft) === 0) {
                detail = 'P';
              } else {
                  if (parseInt(model.lowspeedShaft) === 4) {
                        detail = 'H';
                  } else {
                        detail = 'P';
                }  
              }
          }
        
          return model.shaftTypeHelical.toUpperCase() + detail + parseInt(gearbox.size) + '_' + gearbox.trueRatio.replace('.', '-') + '.png';
      } else {
        if (model.shaftTypeBevel.substring(2, 3) === 'H') {
            if (parseInt(model.lowspeedShaft) === 0) {
              detail = 'P';
            } else {
                if (parseInt(model.lowspeedShaft) === 4) {
                      detail = 'H';
                } else {
                      detail = 'P';
              }  
            }
        }

        return model.shaftTypeBevel.toUpperCase() + detail + parseInt(gearbox.size) + '_' + gearbox.trueRatio.replace('.', '-') + '.png';
      }
  }

  getModelUrl(gearbox) {
      let gSize;
      if (parseInt(gearbox.size).toString().length === 3) {
          gSize = '0' + parseInt(gearbox.size).toString();
      } else {
          gSize = parseInt(gearbox.size).toString();
      }
      return 'Gearbox_Wikov_' + gearbox.stages + 'P' + model.type.substring(0, 1).toUpperCase() + gSize + 'i' + gearbox.nominalRatio + '.step'
  }

  checkParams = () => {
    let pi = 3.141592;

    //normalize values
    if (parseInt(model.unitspwr.current) !== 1) {
        model.gearboxParams.pwr = model.gearboxParams.pwr * model.unitspwr.kw;
    }
    if (parseInt(model.unitshst.hst) !== 2) {
        model.gearboxParams.hst = model.gearboxParams.hst * model.unitshst.knm;
    }    
    if (parseInt(model.unitshst.lst) !== 2) {
        model.gearboxParams.lst = model.gearboxParams.lst * model.unitslst.knm;
    }

    if (model.gearboxParams.lss !== 0 && model.gearboxParams.hss !== 0)  {
      model.gearboxParams.ratio = model.gearboxParams.hss / model.gearboxParams.lss;
    } else {
      model.gearboxParams.ratio = 0;
    }

    if (model.gearboxParams.lst !== 0 && model.fixedParam === "lst") {      
      model.gearboxParams.pwr = model.gearboxParams.lst * 2 * pi * model.gearboxParams.lss / 60;
      model.gearboxParams.hst = 60 * model.gearboxParams.pwr / 2 / pi / model.gearboxParams.hss;      
    }

    if (model.gearboxParams.hst !== 0 && model.fixedParam === "hst") {
      model.gearboxParams.pwr = model.gearboxParams.hst * 2 * pi * model.gearboxParams.hss / 60;
      model.gearboxParams.lst = 60 * model.gearboxParams.pwr / 2 / pi / model.gearboxParams.lss;     

    }

    if (model.gearboxParams.pwr !== 0 && model.fixedParam === "pwr") {
      model.gearboxParams.lst = 60 * model.gearboxParams.pwr / 2 / pi / model.gearboxParams.lss;
      model.gearboxParams.hst = 60 * model.gearboxParams.pwr / 2 / pi / model.gearboxParams.hss;
    }

    //convert values back
    if (parseInt(model.unitspwr.current) === 2) {
        model.gearboxParams.pwr = model.gearboxParams.pwr * 1.34102;
    }
    if (parseInt(model.unitshst.current) === 1) {
        model.gearboxParams.hst = model.gearboxParams.hst * 1000;
    }
    if (parseInt(model.unitshst.current) === 3) {
        model.gearboxParams.hst = model.gearboxParams.hst * 737.562;
    }
    if (parseInt(model.unitslst.current) === 1) {        
        model.gearboxParams.lst = model.gearboxParams.lst * 1000;
    }
    if (parseInt(model.unitslst.current) === 3) {
        model.gearboxParams.lst = model.gearboxParams.lst * 737.562;
    }


    let reject = false;
    if (model.gearboxParams.hss > 6000) {
        reject = true;
    }
    if (this.checkInvalidRatio() && model.gearboxParams.lss !== 0 && model.gearboxParams.hss !== 0) {
        reject = true;
    }
    if (model.gearboxParams.lst * model.unitslst.knm > 400) {
        reject = true;
    }

    if (reject) {
        this.setState({stop: true});
    }
    else {
        this.setState({stop: false});
    }
  }

  checkInvalidRatio() {
    return ((model.type === 'helical' && (model.gearboxParams.ratio < 5 || model.gearboxParams.ratio > 100)) ||
      (model.type === 'bevel' && (model.gearboxParams.ratio < 20 || model.gearboxParams.ratio > 420)))
  }

  onKeyDown(keyEvent)  {
    indexNames = 0;
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {   //block enter
        keyEvent.preventDefault();
    }    
  }

  blockNonNumeric(keyEvent) {
    if (keyEvent.key !== ',' && 
    keyEvent.key !== '.' && 
    keyEvent.key !== '0' &&
    keyEvent.key !== '1' &&
    keyEvent.key !== '2' &&
    keyEvent.key !== '3' &&
    keyEvent.key !== '4' &&
    keyEvent.key !== '5' &&
    keyEvent.key !== '6' &&
    keyEvent.key !== '7' &&
    keyEvent.key !== '8' &&
    keyEvent.key !== '9' &&
    keyEvent.keyCode !== 8 &&
    keyEvent.keyCode !== 9 &&
    keyEvent.keyCode !== 46 &&
    keyEvent.keyCode !== 37 &&
    keyEvent.keyCode !== 38 &&
    keyEvent.keyCode !== 39 &&
    keyEvent.keyCode !== 40) {
        keyEvent.preventDefault();
    }
  }

  blockComma(keyEvent) {     
    if (((keyEvent.charCode || keyEvent.keyCode) === 188) || (keyEvent.key === ',')) {   //block comma
        keyEvent.preventDefault();
    }

    if (keyEvent.key !== ',' && 
    keyEvent.key !== '.' && 
    keyEvent.key !== '0' &&
    keyEvent.key !== '1' &&
    keyEvent.key !== '2' &&
    keyEvent.key !== '3' &&
    keyEvent.key !== '4' &&
    keyEvent.key !== '5' &&
    keyEvent.key !== '6' &&
    keyEvent.key !== '7' &&
    keyEvent.key !== '8' &&
    keyEvent.key !== '9' &&
    keyEvent.keyCode !== 8 &&
    keyEvent.keyCode !== 9 &&
    keyEvent.keyCode !== 46 &&
    keyEvent.keyCode !== 37 &&
    keyEvent.keyCode !== 38 &&
    keyEvent.keyCode !== 39 &&
    keyEvent.keyCode !== 40) {
        keyEvent.preventDefault();
    }
  }

  onClick() {
    indexNames = 0;
  }

  getBearingLife(b10h) {
    if (b10h > 99999) {
    return '> 100,000';
    } else {
    return this.formatNumber(Math.round(b10h/100) * 100);
    }
  }
    

  render() {    
    return (
      <Formik
          initialValues={model}
          onSubmit={this.handleSubmit}
        >
          {({ isSubmitting, props, values, handleBlur, setFieldValue }) => (
        <React.Fragment>
            <Form name="config-form" onKeyDown={this.onKeyDown} onClick={this.onClick}>
        <div className="row">
                <div className="col-md-6 col-lg-8">
                    <div className="row">
                        <div className="col-lg-6 mb-3 mb-lg-0">
                            <div className="block">
                                <h2 className="headline-underline">1. Gearbox type</h2>                                
                                <div className="form-group">
                                    <div className="form-group__input-wrapper">
                                        <div className="row gutter-1">
                                            <div className="col-6" onClick={() => {
                                                        model.type = 'helical';                                                        
                                                        setFieldValue("radiogroup-1", model.type);
                                                        this.setState({modified: true});
                                                        this.checkParams();
                                                        }}>
                                                <div className="gearbox-radio">
                                                    <input type="radio" name="radiogroup-1" id="radio-1-1" className="gearbox-radio__input" checked={(model.type === 'helical') ? true : false} readOnly />
                                                    <label htmlFor="radio-1-1" className="gearbox-radio__label">
                                                        <span className="gearbox-radio__name">Helical</span>
                                                        <img src={'images/' + model.shaftTypeHelical + '.png'} className="gearbox-radio__image gearbox-radio__image--large" alt="Helical gearbox" style={{margin: '1rem'}} />
                                                        <span className="gearbox-radio__info">
                                                            <span className="gearbox-radio__light">Ratio:</span>
                                                            <span>i = 6.3 - 90</span>
                                                        </span>
                                                        <a href="#" className="btn-outline gearbox-radio__btn js_type-modal-trigger" onClick={() => {                                                        
                                                        model.type = 'helical';
                                                        setFieldValue("radiogroup-1", model.type);
                                                        this.setState({modified: true, shaftSelection: true});
                                                        this.checkParams();
                                                    }}>Change shaft</a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6" onClick={() => {
                                                model.type = 'bevel';
                                                setFieldValue("radiogroup-1", model.type);
                                                this.setState({modified: true});
                                                this.checkParams();
                                                }}>
                                                <div className="gearbox-radio">
                                                    <input type="radio" name="radiogroup-1" id="radio-1-2" className="gearbox-radio__input" checked={(model.type === 'bevel') ? true : false} readOnly />
                                                    <label htmlFor="radio-1-2" className="gearbox-radio__label">
                                                        <span className="gearbox-radio__name">Bevel - helical</span>
                                                        <img src={'images/' + model.shaftTypeBevel + '.png'} className="gearbox-radio__image gearbox-radio__image--large" alt="Bevel-helical gearbox" style={{margin: '1rem'}} />
                                                        <span className="gearbox-radio__info">
                                                            <span className="gearbox-radio__light">Ratio:</span>
                                                            <span>i = 22.4 -400</span>
                                                        </span>
                                                        <a href="#" className="btn-outline gearbox-radio__btn js_type-modal-trigger" onClick={() => {                                                        
                                                            model.type = 'bevel';
                                                            setFieldValue("radiogroup-1", model.type);
                                                            this.setState({modified: true, shaftSelection: true});
                                                            this.checkParams();
                                                        }}>Change shaft</a>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="block">
                                <h2 className="headline-underline">2. Input data</h2>
                                <div className="block block--mb-6">
                                    <div className={((model.gearboxParams.hss < 6001 && this.state.externalCooling === true) || (model.gearboxParams.hss < 3001 && this.state.externalCooling === false)) ? "form-group" : "form-group form-group--has-warning" }>
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">High speed shaft (HSS)*</label>
                                            </div>
                                            <div className="col-7">
                                                <div className="form-group__input-wrapper form-group__input-wrapper--custom">                                                   
                                                    <Field name="gearboxParams.hss" type="number" step="any" className="form-control form-control--right" onBlur={e => {
                                                    if (parseFloat(e.target.value) > 0) {
                                                        model.gearboxParams[e.target.name.split(".")[1]] = parseFloat(e.target.value);
                                                        this.checkParams();
                                                        setFieldValue('gearboxParams.ratio', model.gearboxParams.ratio.toFixed(2));
                                                    } else {
                                                        model.gearboxParams.hss = 0;
                                                        setFieldValue('gearboxParams.hss', 0);
                                                    }                                                            
                                                    }} onChange={e => {
                                                    if (parseFloat(e.target.value) > 0) {
                                                        model.gearboxParams[e.target.name.split(".")[1]] = parseInt(e.target.value);
                                                        this.checkParams();
                                                        setFieldValue('gearboxParams.hss', parseInt(e.target.value));
                                                        (model.gearboxParams.lst !== 0) ? setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(2)) : setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(0));
                                                        (model.gearboxParams.hst !== 0) ? setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(2)) : setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(0));                                                          
                                                        (model.gearboxParams.pwr !== 0) ? setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(2)) : setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(0));
                                                        setFieldValue('gearboxParams.ratio', model.gearboxParams.ratio.toFixed(2));
                                                        this.setState({modified: true});
                                                    } else {
                                                        model.gearboxParams.hss = 0;
                                                        model.gearboxParams.ratio = 0;
                                                        model.gearboxParams.pwr = 0;
                                                        model.gearboxParams.hst = 0;
                                                        model.gearboxParams.lst = 0;
                                                        setFieldValue('gearboxParams.ratio', model.gearboxParams.ratio.toFixed(0));
                                                        setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(0));
                                                        setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(0));                                                          
                                                        setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(0));
                                                        setFieldValue('gearboxParams.hss', 0);
                                                    }
                                                    }} onFocus={(e) => {
                                                        if (parseFloat(e.target.value) === 0) {
                                                            setFieldValue('gearboxParams.hss', '');
                                                        }
                                                    }} onKeyDown={this.blockComma} >
                                                    </Field>                                                        
                                                    <span className="form-group__after-text">RPM</span>                                                    
                                                    {((model.gearboxParams.hss > 6001 && this.state.externalCooling === true) || (model.gearboxParams.hss > 3001 && this.state.externalCooling === false)) ? (
                                                    <div className="form-group__warning-sign js_warning-sign" data-target="#alert-1" onClick={() => {this.setState({warningHss: true})}}></div>
                                                    ) : null }                                                                                                
                                                </div>
                                            </div>
                                            {(this.state.warningHss === true) ? (
                                            <div className="alert alert-warning js_alert" role="alert" id="alert-1">
                                                {(this.state.externalCooling) ? 'The RPM value of the high-speed shaft is too high. Contact us for a customized solution.' : 'The RPM value of the high-speed shaft is too high. The limit value for self-cooled gearboxes is 3,000 RPM. Contact us for a customized solution.'}
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn--quaternary btn--small" data-toggle="modal" data-target="#contact-us" onClick={() => {this.setState({isContactUsSubmitted: false})}}>Contact us</button>
                                                </div>
                                                <button type="button" className="alert__close js_close-alert" aria-label="Close" onClick={() => {this.setState({warningHss: false})}}>
                                                    <svg className="alert__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                                                </button>
                                            </div>
                                            ) : "" }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Low speed shaft (LSS)*</label>
                                            </div>
                                            <div className="col-7">
                                                <div className="form-group__input-wrapper form-group__input-wrapper--custom">                                                       
                                                    <Field name="gearboxParams.lss" className="form-control form-control--right" onBlur={e => {
                                                    const val = e.target.value.replace(',', '.');
                                                    if (parseFloat(val) > 0) {
                                                        model.gearboxParams[e.target.name.split(".")[1]] = parseFloat(val);
                                                        this.checkParams();
                                                        setFieldValue('gearboxParams.ratio', model.gearboxParams.ratio.toFixed(2));
                                                    } else {
                                                        model.gearboxParams.lss = 0;
                                                        setFieldValue('gearboxParams.lss', 0);
                                                        model.gearboxParams.ratio = 0;
                                                        setFieldValue('gearboxParams.ratio', 0);
                                                    }
                                                    }} onChange={e => {
                                                    const val = e.target.value.replace(',', '.');
                                                    if (parseFloat(val) > 0) {    
                                                        model.gearboxParams[e.target.name.split(".")[1]] = parseFloat(val);            
                                                        this.checkParams();
                                                        setFieldValue('gearboxParams.lss', val);
                                                        (model.gearboxParams.lst !== 0) ? setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(2)) : setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(0));
                                                        (model.gearboxParams.hst !== 0) ? setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(2)) : setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(0));                                                          
                                                        (model.gearboxParams.pwr !== 0) ? setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(2)) : setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(0));
                                                        setFieldValue('gearboxParams.ratio', model.gearboxParams.ratio.toFixed(2));
                                                        this.setState({modified: true});
                                                    } else {
                                                        model.gearboxParams.lss = 0;
                                                        setFieldValue('gearboxParams.lss', 0);
                                                        model.gearboxParams.ratio = 0;
                                                        setFieldValue('gearboxParams.ratio', 0);
                                                    }
                                                    }} onFocus={(e) => {
                                                        let val = e.target.value.replace(',', '.');
                                                        if (parseFloat(val) === 0) {
                                                            setFieldValue('gearboxParams.lss', '');
                                                        }
                                                    }} onKeyDown={this.blockNonNumeric}>
                                                    </Field>   
                                                    <span className="form-group__after-text">RPM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(((model.type === 'helical' && (model.gearboxParams.ratio < 5 || model.gearboxParams.ratio > 100)) ||
                                                    (model.type === 'bevel' && (model.gearboxParams.ratio < 20 || model.gearboxParams.ratio > 420))) && model.gearboxParams.lss !== 0 && model.gearboxParams.hss !== 0) ?
                                        "form-group form-group--has-warning" : "form-group" }>
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Gearbox ratio</label>
                                            </div>
                                            <div className="col-7">
                                                <div className="form-group__input-wrapper form-group__input-wrapper--custom">                                                        
                                                    <Field name="gearboxParams.ratio" type="number" className="form-control form-control--right" disabled={true}></Field>
                                                    {(((model.type === 'helical' && (model.gearboxParams.ratio < 5 || model.gearboxParams.ratio > 100)) ||
                                                    (model.type === 'bevel' && (model.gearboxParams.ratio < 20 || model.gearboxParams.ratio > 420))) && model.gearboxParams.lss !== 0 && model.gearboxParams.hss !== 0) ? (
                                                    <div className="form-group__warning-sign js_warning-sign" data-target="#alert-1" onClick={() => {this.setState({warningRatio: true})}}></div>
                                                    ) : "" }
                                                </div>
                                            </div>
                                            {(this.state.warningRatio === true) ? (
                                            <div className="alert alert-warning js_alert" role="alert" id="alert-1">
                                                Gearbox ratio is out of expected range. Please change gearbox type (see available ratio ranges for different gearboxes) or modify shaft RPM.
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn--quaternary btn--small" data-toggle="modal" data-target="#contact-us" onClick={() => {this.setState({isContactUsSubmitted: false})}}>Contact us</button>
                                                </div>
                                                <button type="button" className="alert__close js_close-alert" aria-label="Close" onClick={() => {this.setState({warningRatio: false})}}>
                                                    <svg className="alert__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                                                </button>
                                            </div>
                                            ) : "" }
                                        </div>
                                    </div>
                                </div>
                                <div className="block block--mb-6">
                                    <div className="form-group">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <div className="form-check">
                                                    <input name="gearbox-param-radio" id="pwr-gearbox" type="radio" className="form-check-input" value="pwr" checked={model.fixedParam === 'pwr'} onChange={() => {
                                                        model.fixedParam = 'pwr';
                                                        setFieldValue("gearbox-param-radio", "pwr");                                                    
                                                    }}
                                                    />
                                                    <label className="form-check-label" htmlFor="pwr-gearbox">Motor power*</label>
                                                </div>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                    <div className="row gutter-1 align-items-center">
                                                        <div className="col-7">
                                                            <Field name="gearboxParams.pwr" className="form-control form-control--right" size="8" disabled={(model.gearboxParams.ratio !== 0) ? false : true} onBlur={e => {                  
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) > 0) {
                                                                model.gearboxParams[e.target.name.split(".")[1]] = parseFloat(val);
                                                                model.fixedParam = 'pwr';  
                                                                this.checkParams();
                                                                (model.gearboxParams.lst !== 0) ? setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(2)) : setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(0));
                                                                (model.gearboxParams.hst !== 0) ? setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(2)) : setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(0));                                                          
                                                                (model.gearboxParams.pwr !== 0) ? setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(2)) : setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(0));
                                                                setFieldValue('gearbox-param-radio', 'pwr');
                                                                this.setState({modified: true});
                                                            }
                                                            else {
                                                                model.gearboxParams.pwr = 0;
                                                                model.gearboxParams.lst = 0;
                                                                model.gearboxParams.hst = 0;
                                                                setFieldValue('gearboxParams.pwr', 0);
                                                                setFieldValue('gearboxParams.lst', 0);
                                                                setFieldValue('gearboxParams.hst', 0);
                                                            }
                                                            }} onClick={() => {
                                                            model.fixedParam = 'pwr';
                                                            setFieldValue('gearbox-param-radio', 'pwr');
                                                            }} onFocus={(e) => {
                                                                const val = e.target.value.replace(',', '.');
                                                                if (parseFloat(val) === 0) {
                                                                    setFieldValue('gearboxParams.pwr', '');
                                                                }
                                                            }} onKeyDown={this.blockNonNumeric}>
                                                            </Field>
                                                        </div>
                                                        <div className="col-5">
                                                        <Field name="unitspwr" component="select" disabled={(model.gearboxParams.ratio !== 0) ? false : true} onChange={(e) => {
                                                            model.unitspwr.current = e.target.value;
                                                                                                                        
                                                            if (parseInt(e.target.value) === 1) {
                                                                model.gearboxParams.pwr = model.gearboxParams.pwr * model.unitspwr.kw;
                                                                setFieldValue("gearboxParams.pwr", model.gearboxParams.pwr.toFixed(2));
                                                                model.unitspwr.kw = 1;
                                                                model.unitspwr.hp = 1.34102;                                                         
                                                            }
                                                            else if (parseInt(e.target.value) === 2) {
                                                                model.gearboxParams.pwr = model.gearboxParams.pwr * model.unitspwr.hp;
                                                                setFieldValue("gearboxParams.pwr", model.gearboxParams.pwr.toFixed(2));
                                                                model.unitspwr.kw = 0.7457;
                                                                model.unitspwr.hp = 1;                                                          
                                                            }                                                        
                                                            setFieldValue("unitspwr", model.unitspwr.current);
                                                            }}>
                                                            <option value="1">kW</option>                  
                                                            <option value="2">hp</option>                 
                                                        </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <div className="form-check">                                                
                                                    <input name="gearbox-param-radio" id="hst-gearbox" className="form-check-input" type="radio" value="hst" checked={model.fixedParam === 'hst'} onChange={() => {
                                                    model.fixedParam = 'hst';
                                                    setFieldValue("gearbox-param-radio", "hst");
                                                    }}
                                                    />
                                                    <label className="form-check-label" htmlFor="hst-gearbox" style={{width: "101%"}}>High speed torque*</label>
                                                </div>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                    <div className="row gutter-1 align-items-center">
                                                        <div className="col-7">
                                                            <Field name="gearboxParams.hst" className="form-control form-control--right" disabled={(model.gearboxParams.ratio !== 0) ? false : true} onBlur={(e) => {                  
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) > 0) {
                                                                model.gearboxParams[e.target.name.split(".")[1]] = parseFloat(val);
                                                                model.fixedParam = 'hst';
                                                                this.checkParams();
                                                                (model.gearboxParams.lst !== 0) ? setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(2)) : setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(0));
                                                                (model.gearboxParams.hst !== 0) ? setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(2)) : setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(0));                                                          
                                                                (model.gearboxParams.pwr !== 0) ? setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(2)) : setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(0));
                                                                setFieldValue('gearbox-param-radio', 'hst')
                                                                this.setState({modified: true});
                                                            } else {
                                                                model.gearboxParams.pwr = 0;
                                                                model.gearboxParams.lst = 0;
                                                                model.gearboxParams.hst = 0;
                                                                setFieldValue('gearboxParams.pwr', 0);
                                                                setFieldValue('gearboxParams.lst', 0);
                                                                setFieldValue('gearboxParams.hst', 0);
                                                            }
                                                            }} onClick={() => {
                                                            model.fixedParam = 'hst';
                                                            setFieldValue('gearbox-param-radio', 'hst');
                                                            }} onFocus={(e) => {
                                                                const val = e.target.value.replace(',', '.');
                                                                if (parseFloat(val) === 0) {
                                                                    setFieldValue('gearboxParams.hst', '');
                                                                }
                                                            }} onKeyDown={this.blockNonNumeric}>
                                                            </Field>
                                                        </div>
                                                        <div className="col-5">
                                                        <Field name="unitshst" component="select" disabled={(model.gearboxParams.ratio !== 0) ? false : true} value={model.unitshst.current} onChange={(e) => {                                                                                                                 
                                                            model.unitshst.current = e.target.value;
                                                                                                                        
                                                            if(parseInt(e.target.value) === 1) {
                                                                model.gearboxParams.hst = model.gearboxParams.hst * model.unitshst.nm;
                                                                setFieldValue("gearboxParams.hst", model.gearboxParams.hst.toFixed(2));
                                                                model.unitshst.nm = 1;
                                                                model.unitshst.knm = 0.001;
                                                                model.unitshst.ftlb = 0.737562;                                                            
                                                            }
                                                            else if(parseInt(e.target.value) === 2) {
                                                                model.gearboxParams.hst = model.gearboxParams.hst * model.unitshst.knm;
                                                                setFieldValue("gearboxParams.hst", model.gearboxParams.hst.toFixed(2));
                                                                model.unitshst.nm = 1000;
                                                                model.unitshst.knm = 1;
                                                                model.unitshst.ftlb = 737.562;
                                                            }
                                                            else if(parseInt(e.target.value) === 3) {
                                                                model.gearboxParams.hst = model.gearboxParams.hst * model.unitshst.ftlb;
                                                                setFieldValue("gearboxParams.hst", model.gearboxParams.hst.toFixed(2));
                                                                model.unitshst.nm = 1.355822;
                                                                model.unitshst.knm = 0.001355822;
                                                                model.unitshst.ftlb = 1;
                                                            }    
                                                            setFieldValue("unitshst", model.unitshst.current);                                                          
                                                            }}>
                                                            <option value="1">Nm</option>
                                                            <option value="2">kNm</option>
                                                            <option value="3">lb-ft</option>                  
                                                        </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(model.gearboxParams.lst * model.unitslst.knm > 400) ? "form-group form-group--has-warning" : "form-group" }>
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <div className="form-check">                                                
                                                    <input name="gearbox-param-radio" className="form-check-input" id="lst-gearbox" type="radio" value="lst" checked={model.fixedParam === 'lst'} onChange={() => {
                                                        model.fixedParam = 'lst';
                                                        setFieldValue("gearbox-param-radio", "lst");
                                                    }}                    
                                                    />
                                                    <label className="form-check-label" htmlFor="lst-gearbox">Low speed torque*</label>
                                                </div>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                    <div className="row gutter-1 align-items-center">
                                                        <div className="col-7">
                                                            <Field name="gearboxParams.lst" className="form-control form-control--right" disabled={(model.gearboxParams.ratio !== 0) ? false : true} onBlur={(e) => {                  
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) > 0) {
                                                                model.gearboxParams[e.target.name.split(".")[1]] = parseFloat(val);
                                                                model.fixedParam = 'lst';
                                                                this.checkParams();
                                                                (model.gearboxParams.lst !== 0) ? setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(2)) : setFieldValue('gearboxParams.lst', model.gearboxParams.lst.toFixed(0));
                                                                (model.gearboxParams.hst !== 0) ? setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(2)) : setFieldValue('gearboxParams.hst', model.gearboxParams.hst.toFixed(0));                                                          
                                                                (model.gearboxParams.pwr !== 0) ? setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(2)) : setFieldValue('gearboxParams.pwr', model.gearboxParams.pwr.toFixed(0));
                                                                setFieldValue("gearbox-param-radio", "lst");
                                                                this.setState({modified: true});
                                                            } else {
                                                                model.gearboxParams.pwr = 0;
                                                                model.gearboxParams.lst = 0;
                                                                model.gearboxParams.hst = 0;
                                                                setFieldValue('gearboxParams.pwr', 0);
                                                                setFieldValue('gearboxParams.lst', 0);
                                                                setFieldValue('gearboxParams.hst', 0);
                                                            }                                                            
                                                            }} onClick={() => {
                                                            model.fixedParam = 'lst';
                                                            setFieldValue("gearbox-param-radio", "lst");
                                                            }} onFocus={(e) => {
                                                                const val = e.target.value.replace(',', '.');
                                                                if (parseFloat(val) === 0) {
                                                                    setFieldValue('gearboxParams.lst', '');
                                                                }
                                                            }} onKeyDown={this.blockNonNumeric}></Field>
                                                        </div>
                                                        <div className="col-5">
                                                        <Field name="unitslst" component="select" disabled={(model.gearboxParams.ratio !== 0) ? false : true} value={model.unitslst.current} onChange={(e) => {
                                                            model.unitslst.current = parseInt(e.target.value);
                                                                                                                        
                                                            if (parseInt(e.target.value) === 1) {
                                                                model.gearboxParams.lst = model.gearboxParams.lst * model.unitslst.nm;
                                                                setFieldValue("gearboxParams.lst", model.gearboxParams.lst.toFixed(2));
                                                                model.unitslst.nm = 1;
                                                                model.unitslst.knm = 0.001;
                                                                model.unitslst.ftlb = 0.737562;
                                                            }
                                                            else if (parseInt(e.target.value) === 2) {
                                                                model.gearboxParams.lst = model.gearboxParams.lst * model.unitslst.knm;
                                                                setFieldValue("gearboxParams.lst", model.gearboxParams.lst.toFixed(2));
                                                                model.unitslst.nm = 1000;
                                                                model.unitslst.knm = 1;
                                                                model.unitslst.ftlb = 737.562;
                                                            }
                                                            else if (parseInt(e.target.value) === 3) {
                                                                model.gearboxParams.lst = model.gearboxParams.lst * model.unitslst.ftlb;
                                                                setFieldValue("gearboxParams.lst", model.gearboxParams.lst.toFixed(2));
                                                                model.unitslst.nm = 1.355822;
                                                                model.unitslst.knm = 0.001355822;
                                                                model.unitslst.ftlb = 1;
                                                            }    
                                                            setFieldValue("unitslst", model.unitslst.current);
                                                        }}>
                                                        <option value="1">Nm</option>
                                                        <option value="2">kNm</option>
                                                        <option value="3">lb-ft</option>                    
                                                        </Field>                                                        
                                                        </div>
                                                    </div>
                                                    {(model.gearboxParams.lst * model.unitslst.knm > 400) ? (
                                                    <div className="form-group__warning-sign js_warning-sign" data-target="#alert-1" onClick={() => {this.setState({warningLst: true})}}></div>
                                                    ) : "" }
                                                </div>
                                            </div>
                                            {(this.state.warningLst === true) ? (
                                            <div className="alert alert-warning js_alert" role="alert" id="alert-1">
                                                Your Low speed torque is outside of basic WinGear catalogue range. For customized solution, please contact us.
                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn--quaternary btn--small" data-toggle="modal" data-target="#contact-us" onClick={() => {this.setState({isContactUsSubmitted: false})}}>Contact us</button>
                                                </div>
                                                <button type="button" className="alert__close js_close-alert" aria-label="Close" onClick={() => {this.setState({warningLst: false})}}>
                                                    <svg className="alert__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                                                </button>
                                            </div>
                                            ) : "" }
                                        </div>
                                    </div>
                                </div>
                                <div className="block block--mb-10">
                                    <div className="form-group">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Industry</label>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">                                            
                                                <Field name="applications" component="select" disabled={(this.state.industry_options.length === 0) ? true : false} onChange={                                                
                                                            (e) => {
                                                            this.setState({ loading: true });
                                                            model.industry = e.target.value;
                                                            fetch(config.server + '/wingear/api/drivenmachines/' + e.target.value, {
                                                                headers: new Headers({
                                                                "Authorization": basic
                                                                }),
                                                            })
                                                            .then(response => {
                                                                return response.json();
                                                            }).then(result => {
                                                                let options = result.map((opt => {
                                                                    return (
                                                                        <option value={opt.id}>{opt.name}</option>
                                                                    )
                                                                })
                                                                )
                                                                this.setState({ drivenMachine_options: options });
                                                            });
                                                            model.ka = 0;
                                                            (model.ka !== 0) ? setFieldValue("ka", model.ka.toFixed(2)) : setFieldValue("ka", model.ka.toFixed(0));
                                                            setFieldValue("drivenmachines", "choose");         
                                                            this.setState({ loading: false })                                                                         
                                                            }                          
                                                        }>
                                                    <option value="0">choose</option>
                                                    <optgroup label="Industry">
                                                        {this.state.industry_options}
                                                    </optgroup>
                                                    <optgroup label="Application">
                                                        {this.state.application_options}
                                                    </optgroup>
                                                </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block block--mb-10">
                                    <div className="form-group">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Driven machines</label>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                <Field name="drivenmachines" component="select" disabled={(this.state.drivenMachine_options.length === 0) ? true : false} onChange={
                                                    (e) => {   
                                                    model.application = e.target.value
                                                    if(e.target.value !== 0) {
                                                        fetch(config.server + '/api/dailyperiods/' + e.target.value, {                      
                                                        headers: new Headers({
                                                            "Authorization": basic
                                                        }),
                                                        })
                                                        .then(response => {
                                                            return response.json();
                                                        })
                                                        .then(result => {
                                                        result.map((opt) => {
                                                            (opt.lessThan3 !== null) ? model.effectiveDaily.lessThan3 = opt.lessThan3 : (opt.between3And10 !== null) ? model.effectiveDaily.lessThan3 = opt.between3And10 : (opt.higherThan10 !== null) ? model.effectiveDaily.lessThan3 = opt.higherThan10 : model.effectiveDaily.lessThan3 = 0;
                                                            (opt.between3And10 !== null) ? model.effectiveDaily.between3And10 = opt.between3And10 : (opt.higherThan10 !== null) ? model.effectiveDaily.between3And10 = opt.higherThan10 : model.effectiveDaily.between3And10 = 0;
                                                            (opt.higherThan10 !== null) ? model.effectiveDaily.higherThan10 = opt.higherThan10 : model.effectiveDaily.higherThan10 = 0;
                                                            return null;
                                                        })                                                      
                                                        if(model.effectiveDaily.selected === 'low') {
                                                            model.ka = parseFloat(model.effectiveDaily.lessThan3);
                                                        }
                                                        else if(model.effectiveDaily.selected === 'mid') {
                                                            model.ka = parseFloat(model.effectiveDaily.between3And10);
                                                        }
                                                        else if(model.effectiveDaily.selected === 'high') {
                                                            model.ka = parseFloat(model.effectiveDaily.higherThan10);
                                                        }
                                                        else {
                                                            model.ka = 0;                                                        
                                                        }                                                      
                                                        (parseFloat(model.ka) !== 0) ? setFieldValue("ka", model.ka.toFixed(2)) : setFieldValue("ka", model.ka.toFixed(0));
                                                        })  
                                                        setFieldValue("drivenmachines", e.target.value);                                                                                                  
                                                    }
                                                    else {
                                                        model.ka = 0;
                                                        setFieldValue("drivenmachines", e.target.value);
                                                        setFieldValue("effectivedailyperiod", "");
                                                        (parseFloat(model.ka) !== 0) ? setFieldValue("ka", model.ka.toFixed(2)) : setFieldValue("ka", model.ka.toFixed(0));
                                                        model.effectiveDaily.lessThan3 = 0;
                                                        model.effectiveDaily.between3And10 = 0;
                                                        model.effectiveDaily.higherThan10 = 0;                                                    
                                                    }
                                                    }
                                                }>       
                                                    <option value="0">choose</option>
                                                    {this.state.drivenMachine_options}
                                                </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block block--mb-10">
                                    <div className="separator"></div>
                                    <div className="form-group form-group--mb-8 form-group--has-help">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <div className="form-group__input-wrapper  form-group__input-wrapper--inline-block">
                                                    <div className="switch">
                                                        <input type="checkbox" checked={this.state.externalCooling} className="switch__input" id="switch-1" onChange={() => {                                                        
                                                            const currentState = this.state.externalCooling;
                                                            this.setState({externalCooling: !currentState});
                                                            this.setState({modified: true});
                                                         }} />
                                                        <label className="switch__label" htmlFor="switch-1">
                                                            <span className="switch__text">Cooled by external unit</span>
                                                            <span className="switch__button"></span>
                                                            <span className="switch__text">Self cooled gearbox</span>
                                                        </label>
                                                    </div>
                                                    <div className="help" data-toggle="popover" title="" data-placement="top" data-content="Cooled by external unit: <br />Forced lubrication with an external lubrication unit. <br /><br />Self cooled gearbox: <br />Splash lubricated gearbox without a lubrication unit. Cooled either passively or by a fan mounted on the high-speed shaft." tabIndex="0" data-original-title="Gearbox cooling">
                                                        <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div style={{display: (this.state.externalCooling) ? 'none' : 'block'}}>
                                        <div className="form-group form-group--has-help">
                                            <div className="row gutter-10 align-items-center">
                                                <div className="col-5">
                                                    <label className="label">Duty cycle per hour*</label>
                                                </div>
                                                <div className="col-7 col-sm-6">
                                                    <div className="form-group__input-wrapper form-group__input-wrapper--custom-2">
                                                        <Field name="dutycycle" component="select" onChange={(e) => {                                                  
                                                            setFieldValue('dutycycle', e.target.value);
                                                            model.dutycycle = e.target.value;
                                                            this.setState({
                                                                modified: true,
                                                                listDutyCycle: e.target.selectedIndex
                                                                });
                                                        }}>
                                                            <option value="0">choose</option>
                                                            {this.state.dutycycle_options}
                                                        </Field>
                                                        <span className="form-group__after-text">%</span>
                                                        <div className="help" data-toggle="popover" title="" data-placement="top" data-content="Percentage of gearbox operation per hour: Longest continuous gearbox operation <img src='images/DCPH.png' />" tabIndex="0" data-original-title="Duty cycle per hour">
                                                            <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row gutter-10 align-items-center">
                                                <div className="col-5">
                                                    <label className="label">Ambient temperature*</label>
                                                </div>
                                                <div className="col-7 col-sm-6">
                                                    <div className="form-group__input-wrapper form-group__input-wrapper--custom-2">
                                                        <Field name="ambienttemperature" component="select" onChange={(e) => {                                                  
                                                            setFieldValue('ambienttemperature', e.target.value);
                                                            model.ambienttemperature = e.target.value;
                                                            this.setState({
                                                                modified: true,
                                                                listAmbientTemperature: e.target.selectedIndex
                                                                });
                                                        }}>
                                                            <option value="0">choose</option>
                                                            {this.state.ambienttemperature_options}
                                                        </Field>
                                                        <span className="form-group__after-text">°C</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group form-group--has-help">
                                            <div className="row gutter-10 align-items-center">
                                                <div className="col-5">
                                                    <label className="label">Utilization*</label>
                                                </div>
                                                <div className="col-7 col-sm-6">
                                                    <div className="form-group__input-wrapper form-group__input-wrapper--custom-2">
                                                        <Field name="utilization" component="select" onChange={(e) => {                                                  
                                                            setFieldValue('utilization', e.target.value);
                                                            model.utilization = e.target.value;
                                                            this.setState({
                                                                modified: true,
                                                                listUtilization: e.target.selectedIndex
                                                                });
                                                        }}>
                                                            <option value="0">choose</option>
                                                            {this.state.utilization_options}
                                                        </Field>
                                                        <span className="form-group__after-text">%</span>
                                                        <div className="help" data-toggle="popover" title="" data-placement="top" data-content="Average percentage of power transmitted by the gearbox." tabIndex="0" data-original-title="Utilization">
                                                            <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row gutter-10 align-items-center">
                                                <div className="col-5">
                                                    <label className="label">Average speed of air flow*</label>
                                                </div>
                                                <div className="col-7 col-sm-6">
                                                    <div className="form-group__input-wrapper form-group__input-wrapper--custom-2">
                                                        <Field name="airflow" component="select" onChange={(e) => {                                                  
                                                            setFieldValue('airflow', e.target.value);
                                                            model.airflow = e.target.value;
                                                            this.setState({
                                                                modified: true,
                                                                listAirFlow: e.target.selectedIndex
                                                                });
                                                        }}>
                                                            <option value="0">choose</option>
                                                            {this.state.airflow_options}
                                                        </Field>
                                                        <span className="form-group__after-text">m/s</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>                                
                                <div className="block block--mb-10">
                                    <div className="separator"></div>
                                    <div className="form-group">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Effective operating period per day</label>
                                            </div>
                                            <div className="col-7">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="effectivedailyperiod" id="effectivedailyperiod-less" value={model.effectiveDaily.lessThan3} disabled={(this.state.drivenMachine_options.length === 0) ? true : false} onChange={(e) => {                                                      
                                                        model.recommendedKa = parseFloat(e.target.value);
                                                        model.ka = parseFloat(model.recommendedKa);
                                                        model.effectiveDaily.selected = 'low';
                                                        (model.ka !== 0) ? setFieldValue("ka", model.ka.toFixed(2)) : setFieldValue("ka", model.ka.toFixed(0));
                                                        this.setState({modified: true});
                                                        }}></input>
                                                        <label className="form-check-label" htmlFor="effectivedailyperiod-less">&lt; 3h</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="effectivedailyperiod" id="effectivedailyperiod-between" value={model.effectiveDaily.between3And10} disabled={(this.state.drivenMachine_options.length === 0) ? true : false} onChange={(e) => {
                                                        model.recommendedKa = parseFloat(e.target.value);
                                                        model.ka = parseFloat(model.recommendedKa);
                                                        model.effectiveDaily.selected = 'mid';
                                                        (model.ka !== 0) ? setFieldValue("ka", model.ka.toFixed(2)) : setFieldValue("ka", model.ka.toFixed(0));
                                                        this.setState({modified: true});
                                                        }}></input>
                                                        <label className="form-check-label" htmlFor="effectivedailyperiod-between">3-10 h</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="effectivedailyperiod" id="effectivedailyperiod-higher" value={model.effectiveDaily.higherThan10} disabled={(this.state.drivenMachine_options.length === 0) ? true : false} onChange={(e) => {
                                                        model.recommendedKa = parseFloat(e.target.value);
                                                        model.ka = parseFloat(model.recommendedKa);
                                                        model.effectiveDaily.selected = 'high';
                                                        (model.ka !== 0) ? setFieldValue("ka", model.ka.toFixed(2)) : setFieldValue("ka", model.ka.toFixed(0));
                                                        this.setState({modified: true});
                                                        }}></input>
                                                        <label className="form-check-label" htmlFor="effectivedailyperiod-higher">&gt; 10h</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block block--mb-6">
                                    <div className="form-group form-group--has-help">
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label label--bold">Application factor KA*</label>
                                            </div>
                                            <div className="col-7 col-sm-5">
                                                <div className="form-group__input-wrapper form-group__input-wrapper--inline-block">
                                                    <div className="form-group__custom-1">
                                                        <Field name="ka" className="form-control form-control--right" onBlur={(e) => {
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) > 0.84) {
                                                                model.ka = parseFloat(val);                                                                                                             
                                                                (model.ka !== 0) ? setFieldValue('ka', model.ka.toFixed(2)) : setFieldValue('ka', model.ka.toFixed(0));
                                                                this.setState({modified: true});
                                                            } else {
                                                                setFieldValue('ka', 0);
                                                                model.ka = 0;
                                                            }
                                                        }}  onFocus={(e) => {
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) === 0) {
                                                                setFieldValue('ka', '');
                                                            }
                                                        }} onKeyDown={this.blockNonNumeric} >
                                                        </Field>
                                                        {(model.recommendedKa !== 0) ? (
                                                        <span className="form-group__after-text form-group__after-text--small">recommended by Wikov {model.recommendedKa}</span>
                                                        ) : ""}
                                                    </div>                                                
                                                    <div className="help" data-toggle="popover" title="Application factor kA" data-placement="top" data-content="The application factor compensates for any uncertainties in loads and impacts and directly influences required gearbox rated torque. <img src='images/ka.png' />" tabIndex="0">
                                                        <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block block--mb-10">
                                    <div className={(this.state.listStartsPerHour === 0 && this.state.listPrimeMover !== 0) ? "form-group form-group--has-help form-group--has-warning" : "form-group form-group--has-help" }>
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Starts per hour</label>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                <Field name="peaksperhour" component="select" onChange={(e) => {                                                  
                                                    setFieldValue('peaksperhour', e.target.value);
                                                    model.cphss = e.target.value;
                                                    this.setState({
                                                        modified: true,
                                                        listStartsPerHour: e.target.selectedIndex
                                                        });
                                                }}>
                                                    <option value="0">choose</option>
                                                    {this.state.peaksperhour_options}
                                                </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(this.state.listStartsPerHour !== 0 && this.state.listPrimeMover === 0) ? "form-group form-group--has-help form-group--has-warning" : "form-group form-group--has-help" }>
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Prime mover</label>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                <Field name="primemover" component="select" onChange={(e) => {                                                  
                                                    setFieldValue('primemover', e.target.value);
                                                    model.cs = e.target.value;
                                                    this.setState({
                                                        modified: true,
                                                        listPrimeMover: e.target.selectedIndex
                                                        });
                                                }}>
                                                    <option value="0">choose</option>
                                                    {this.state.primemover_options}
                                                </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(this.state.listStaticPeaksPerHour === 0 && model.mstatic !== 0) ? "form-group form-group--has-help form-group--has-warning" : "form-group form-group--has-help" }>
                                        <div className="row gutter-10 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Static peaks per hour</label>
                                            </div>
                                            <div className="col-7 col-sm-6">
                                                <div className="form-group__input-wrapper">
                                                <Field name="staticpeaksperhour" component="select" onChange={(e) => {
                                                    setFieldValue('staticpeaksperhour', e.target.value);
                                                    model.cplss = e.target.value;
                                                    this.setState({
                                                        modified: true,
                                                        listStaticPeaksPerHour: e.target.selectedIndex
                                                        });
                                                }} onKeyDown={this.blockComma}>
                                                    <option value="0">choose</option>
                                                    {this.state.peaksperhour_options}
                                                </Field>                                                
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className={(this.state.listStaticPeaksPerHour !== 0 && model.mstatic === 0) ? "form-group form-group--has-help form-group--has-warning" : "form-group form-group--has-help" }>
                                    <div className="row gutter-10 align-items-center">
                                        <div className="col-5">
                                            <label className="label">Static torque on low&#8209;speed shaft</label>
                                        </div>
                                        <div className="col-7 col-sm-6">
                                            <div className="form-group__input-wrapper">
                                                <div className="row gutter-1 align-items-center">
                                                    <div className="col-7">                                                        
                                                        <Field name="mstatic" className="form-control form-control--right" onBlur={(e) => {
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) > 0) {
                                                                model.mstatic = parseFloat(val);                                                                                                             
                                                                (model.mstatic !== 0) ? setFieldValue('mstatic', model.mstatic.toFixed(2)) : setFieldValue('mstatic', model.mstatic.toFixed(0));
                                                                this.setState({modified: true});
                                                            }
                                                            else {
                                                                setFieldValue('mstatic', 0);
                                                                model.mstatic = 0;
                                                            }
                                                        }} onFocus={(e) => {
                                                            const val = e.target.value.replace(',', '.');
                                                            if (parseFloat(val) === 0) {
                                                                setFieldValue('mstatic', '');
                                                            }
                                                        }} onKeyDown={this.blockNonNumeric} >
                                                        </Field>                                                    
                                                    </div>
                                                    <div className="col-5">
                                                        <Field name="unitsmst" component="select" value={model.unitsmst.current} onChange={(e) => {                                                                                                                 
                                                            model.unitsmst.current = e.target.value;
                                                                                                                    
                                                            if(parseInt(e.target.value) === 1) {
                                                                model.mstatic = parseFloat(model.mstatic * model.unitsmst.nm).toFixed(2);
                                                                setFieldValue('mstatic', model.mstatic);
                                                                model.unitsmst.nm = 1;
                                                                model.unitsmst.knm = 0.001;
                                                                model.unitsmst.ftlb = 0.737562;                                                            
                                                            }
                                                            else if(parseInt(e.target.value) === 2) {
                                                                model.mstatic = parseFloat(model.mstatic * model.unitsmst.knm).toFixed(2);
                                                                setFieldValue('mstatic', model.mstatic);
                                                                model.unitsmst.nm = 1000;
                                                                model.unitsmst.knm = 1;
                                                                model.unitsmst.ftlb = 737.562;
                                                            }
                                                            else if(parseInt(e.target.value) === 3) {
                                                                model.mstatic = parseFloat(model.mstatic * model.unitsmst.ftlb).toFixed(2);
                                                                setFieldValue('mstatic', model.mstatic);
                                                                model.unitsmst.nm = 1.355822;
                                                                model.unitsmst.knm = 0.001355822;
                                                                model.unitsmst.ftlb = 1;
                                                            }    
                                                            setFieldValue("unitsmst", model.unitsmst.current);                                                          
                                                        }}>
                                                        <option value="1">Nm</option>
                                                        <option value="2">kNm</option>
                                                        <option value="3">lb-ft</option>                  
                                                        </Field>
                                                    </div>                                                                                          
                                                </div>
                                                <div className="help" data-toggle="popover" title="Static torque on low&#8209;speed shaft" data-placement="top" data-content="Maximum peak torque that occurs regularly on low&#8209;speed shaft. <img src='images/SP_graf.png' />" tabIndex="0">
                                                    <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                </div>                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3 mb-lg-0">
                        <div className="block">
                                <h2 className="headline-underline">3. Operating conditions</h2>
                                <div className="block">
                                    <div className="row">
                                        <div className="col-6">
                                        <div className="gearbox-rotation js_gearbox-rotation">                                
                                                <div className="gearbox-rotation__form">
                                                    <label className="gearbox-rotation__label">Angle</label>
                                                    <Field name='angle1' type="number" className="form-control gearbox-rotation__input js_gearbox-rotation-input" onChange={(e) => {
                                                        model.angle1 = parseInt(e.target.value);
                                                        if (model.angle1 > 180 || model.angle1 < -180) {
                                                            model.angle1 = 0;
                                                        }
                                                        this.setState({ negativeAngle1 : -model.angle1})
                                                        if (model.angle1 > 0) {
                                                            this.setState({ transformedAngle1 : -model.angle1})
                                                        } else {
                                                            this.setState({ transformedAngle1 : -(180 - Math.abs(model.angle1)) })
                                                        }
                                                        setFieldValue('angle1', model.angle1);
                                                    }} onFocus={(e) => {
                                                        if (parseFloat(e.target.value) === 0) {
                                                            setFieldValue('angle1', '');
                                                        }
                                                    }} onKeyDown={this.blockComma}/>
                                                    <span className="gearbox-rotation__deg">°</span>
                                                </div>
                                                <div className="gearbox-rotation__wheel" 
                                                onMouseDown={(e) => { this.setState({ buttonPressed : true }) }}
                                                onMouseUp={(e) => { this.setState({ buttonPressed : false }) }}
                                                onMouseMove={(e) => {  
                                                    if (this.state.buttonPressed) {
                                                        const midpointX = (e.target.getBoundingClientRect().right - e.target.getBoundingClientRect().left) / 2.0;
                                                        const midpointY = (e.target.getBoundingClientRect().bottom - e.target.getBoundingClientRect().top) / 2.0;
                                                        
                                                        const vectorBegX = e.target.getBoundingClientRect().right - midpointX;
                                                        const vectorBegY = 0;
                                                        const vectorArrX = (e.clientX - e.target.getBoundingClientRect().left) - midpointX;
                                                        const vectorArrY = (e.clientY - e.target.getBoundingClientRect().top) - midpointY;
                                                        
                                                        const dot =  vectorBegX * vectorArrX + vectorBegY * vectorArrY;
                                                        const sc1 = Math.pow((Math.pow(vectorBegX, 2) + Math.pow(vectorBegY, 2)), 1/2);
                                                        const sc2 = Math.pow((Math.pow(vectorArrX, 2) + Math.pow(vectorArrY, 2)), 1/2);
                                                                                                    
                                                        const cosAngle = dot / (sc1 * sc2);
                                                        const angle1 = Math.round(Math.acos(cosAngle) * (180/Math.PI));
                                                        
                                                        if ((e.clientY - e.target.getBoundingClientRect().top) < midpointY) {
                                                            model.angle1 = angle1;
                                                        } else {
                                                            model.angle1 = -angle1;
                                                        }
                                                        this.setState({ negativeAngle1 : -model.angle1})
                                                        if (model.angle1 > 0) {
                                                            this.setState({ transformedAngle1 : -model.angle1})
                                                        } else {
                                                            this.setState({ transformedAngle1 : -(180 - Math.abs(model.angle1)) })
                                                        }
                                                        setFieldValue('angle1', model.angle1);
                                                    }
                                                }} >
                                                    <div className="gearbox-rotation__in js_in" style={{backgroundImage: model.angle1 > 0 ? "linear-gradient(transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle1 + "deg, rgb(241, 94, 16) 50%, transparent 50%)" : "linear-gradient(0deg, transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle1 + "deg, rgb(241, 94, 16) 50%, transparent 50%)"}}></div>
                                                    <img className="gearbox-rotation__scale" src="images/svg/scale.svg" alt="Scale" />
                                                    <div className="gearbox-rotation__gear js_gear" style={{backgroundImage: (model.type === 'helical') ? "url('images/wingear/gears/" + model.shaftTypeHelical + "_a.png')" : "url('images/wingear/gears/" + model.shaftTypeBevel + "_a.png')", transform: "rotate(" + this.state.negativeAngle1 + "deg)"}}></div>
                                                    <img className="gearbox-rotation__pointer js_pointer" src="images/svg/pointer.svg" style={{transform: "rotate(" + this.state.negativeAngle1 + "deg)"}} alt="Pointer" />
                                                    <div className="gearbox-rotation__draggable-layer js_draggable-layer" style={{transform: "rotate(322.597deg) translateZ(0px)"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                        {(model.type !== 'coaxial') ? (
                                        <div className="col-6">
                                            <div className="gearbox-rotation js_gearbox-rotation">
                                                <div className="gearbox-rotation__form">
                                                    <label className="gearbox-rotation__label">Angle</label>
                                                    <Field name='angle2' type="number" className="form-control gearbox-rotation__input js_gearbox-rotation-input" onChange={(e) => {
                                                    model.angle2 = parseInt(e.target.value);
                                                    if (model.angle2 > 180 || model.angle2 < -180) {
                                                        model.angle2 = 0;
                                                    }
                                                    this.setState({ negativeAngle2 : -model.angle2})
                                                    if (model.angle2 > 0) {
                                                        this.setState({ transformedAngle2 : -model.angle2})
                                                    } else {
                                                        this.setState({ transformedAngle2 : -(180 - Math.abs(model.angle2)) })
                                                    }
                                                    setFieldValue('angle2', model.angle2);
                                                }} onFocus={(e) => {
                                                    if (parseFloat(e.target.value) === 0) {
                                                        setFieldValue('angle2', '');
                                                    }
                                                }} onKeyDown={this.blockComma} />
                                                    <span className="gearbox-rotation__deg">°</span>
                                                </div>
                                                <div className="gearbox-rotation__wheel"
                                                onMouseDown={(e) => { this.setState({ buttonPressed : true }) }}
                                                onMouseUp={(e) => { this.setState({ buttonPressed : false }) }}
                                                onMouseMove={(e) => {  
                                                    if (this.state.buttonPressed) {
                                                        const midpointX = (e.target.getBoundingClientRect().right - e.target.getBoundingClientRect().left) / 2.0;
                                                        const midpointY = (e.target.getBoundingClientRect().bottom - e.target.getBoundingClientRect().top) / 2.0;
                                                        
                                                        const vectorBegX = e.target.getBoundingClientRect().right - midpointX;
                                                        const vectorBegY = 0;
                                                        const vectorArrX = (e.clientX - e.target.getBoundingClientRect().left) - midpointX;
                                                        const vectorArrY = (e.clientY - e.target.getBoundingClientRect().top) - midpointY;
                                                        
                                                        const dot =  vectorBegX * vectorArrX + vectorBegY * vectorArrY;
                                                        const sc1 = Math.pow((Math.pow(vectorBegX, 2) + Math.pow(vectorBegY, 2)), 1/2);
                                                        const sc2 = Math.pow((Math.pow(vectorArrX, 2) + Math.pow(vectorArrY, 2)), 1/2);
                                                                                                    
                                                        const cosAngle = dot / (sc1 * sc2);
                                                        const angle2 = Math.round(Math.acos(cosAngle) * (180/Math.PI));
                                                        
                                                        if ((e.clientY - e.target.getBoundingClientRect().top) < midpointY) {
                                                            model.angle2 = angle2;
                                                        } else {
                                                            model.angle2 = -angle2;
                                                        }
                                                        this.setState({ negativeAngle2 : -model.angle2})
                                                        if (model.angle2 > 0) {
                                                            this.setState({ transformedAngle2 : -model.angle2})
                                                        } else {
                                                            this.setState({ transformedAngle2 : -(180 - Math.abs(model.angle2)) })
                                                        }
                                                        setFieldValue('angle2', model.angle2);
                                                    }
                                                }} >
                                                    <div className="gearbox-rotation__in js_in" style={{backgroundImage: model.angle2 > 0 ? "linear-gradient(transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle2 + "deg, rgb(241, 94, 16) 50%, transparent 50%)" : "linear-gradient(0deg, transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle2 + "deg, rgb(241, 94, 16) 50%, transparent 50%)"}}></div>
                                                    <img className="gearbox-rotation__scale" src="images/svg/scale.svg" alt="Scale" />                                            
                                                    <div className="gearbox-rotation__gear js_gear" style={{backgroundImage: (model.type === 'helical') ? "url('images/wingear/gears/" + model.shaftTypeHelical + "_b.png')" : "url('images/wingear/gears/" + model.shaftTypeBevel + "_b.png')", transform: "rotate(" + this.state.negativeAngle2 + "deg)"}}></div>
                                                    <img className="gearbox-rotation__pointer js_pointer" src="images/svg/pointer.svg" style={{transform: "rotate(" + this.state.negativeAngle2 + "deg)"}} alt="Pointer" />
                                                    <div className="gearbox-rotation__draggable-layer js_draggable-layer" style={{transform: "rotate(322.597deg) translateZ(0px)"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                        ) : "" }
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="form-group form-group--has-help">
                                        <div className="row gutter-5 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Mounting arrangement</label>
                                            </div>
                                            <div className="col-7 col-sm-5">
                                                <div className="form-group__input-wrapper">
                                                    <Field name="mounting-arrangement" component="select" onChange={(e) => {                                                    
                                                            model.mountingArrangement = e.target.value;
                                                        }}>
                                                        <option value="0">No preference</option>
                                                        {this.state.arrangement_options}
                                                    </Field>
                                                    
                                                    <div className="help" data-toggle="popover" title="Mounting arrangement" data-placement="top" data-content="<img src='images/mounting_arrangements_w.png' />" tabIndex="0">
                                                        <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row gutter-5 align-items-center">
                                            <div className="col-5">
                                                <label className="label">High speed shaft design</label>
                                            </div>
                                            <div className="col-7 col-sm-5">
                                                <div className="form-group__input-wrapper">
                                                <Field name="highspeed-shaft-design" component="select" onChange={(e) => {
                                                    model.highspeedShaft = e.target.value
                                                    }}>
                                                    <option value="0">No preference</option>
                                                    {this.state.highspeedshaft_options}
                                                </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row gutter-5 align-items-center">
                                            <div className="col-5">
                                                <label className="label">Low speed shaft design</label>
                                            </div>
                                            <div className="col-7 col-sm-5">
                                                <div className="form-group__input-wrapper">
                                                <Field name="lowspeed-shaft-design" component="select" onChange={(e) => {
                                                    model.lowspeedShaft = e.target.value
                                                    if (this.state.gearbox != null) {
                                                        this.setState({ schemaUrl: this.getSchemaUrl(this.state.gearbox) })
                                                    }
                                                    }}>
                                                    <option value="0">No preference</option>
                                                    {(model.type === 'helical') ? ((model.shaftTypeHelical.substring(2, 3) === 'H') ? this.state.lowspeedshaftHollow_options : this.state.lowspeedshaft_options) : ((model.shaftTypeBevel.substring(2, 3) === 'H') ? this.state.lowspeedshaftHollow_options : this.state.lowspeedshaft_options) }
                                                </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="block">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">                                                
                                                        <input type="checkbox" className="form-check-input" checked={model.dusty} id="dusty-environment" onChange={(e) => {                                   
                                                            model.dusty = !model.dusty                        
                                                            setFieldValue("dusty-environment", model.dusty)                                                    
                                                        }} />
                                                        <label className="form-check-label" htmlFor="dusty-environment">Dusty environment</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" checked={model.explosive} id="explosive-environment" onChange={(e) => {
                                                            model.explosive = !model.explosive
                                                            setFieldValue("explosive-environment", model.explosive)
                                                        }} />
                                                        <label className="form-check-label" htmlFor="explosive-environment">Explosive environment</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row gutter-5 align-items-center">
                                        <div className="col-5">
                                            <label className="label">Climate</label>
                                        </div>
                                        <div className="col-7 col-sm-5">
                                            <div className="form-group__input-wrapper">
                                                <Field name="climate" component="select" onChange={(e) => {                                                  
                                                    setFieldValue('climate', e.target.value);
                                                    model.climate = e.target.value;                                            
                                                }}>
                                                <option value="0">choose</option>
                                                {this.state.climate_options}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row gutter-5 align-items-center">
                                        <div className="col-5">
                                            <label className="label">Elevation</label>
                                        </div>
                                        <div className="col-7 col-sm-5">
                                            <div className="form-group__input-wrapper">
                                                <Field name="elevation" component="select" onChange={(e) => {
                                                    setFieldValue('elevation', e.target.value); 
                                                    model.elevation = e.target.value;
                                                }}>
                                                    <option value="0">No preference</option>
                                                    {this.state.elevation_options}
                                                </Field>                                  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="block">
                                <h2 className="headline-underline">
                                    <div className="row">
                                        <div className="col-6">4. External forces</div>
                                        <div className="col-6">
                                            <div className="switch switch--right">
                                                <input type="checkbox" checked={!this.state.externalForces} className="switch__input js_switch-toggle" id="switch-2" onChange={() => {                                                        
                                                    const currentState = this.state.externalForces;
                                                    this.setState({externalForces: !currentState});
                                                    }} />
                                                <label className="switch__label" htmlFor="switch-2">
                                                    <span className="switch__text">No</span>
                                                    <span className="switch__button"></span>
                                                    <span className="switch__text">Yes</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                                <div className="block switch__toggle-block js_switch-toggle-block" id="externalForces" style={{display: (this.state.externalForces) ? 'block' : 'none'}}>
                                    <div className="block block--mb-10">
                                        <div className="row gutter-5">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <div className="row justify-content-center">
                                                        <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                            <label className="label d-block text-center mb-2">HSS radial force</label>
                                                            <div className="form-group__input-wrapper">
                                                                <div className="row gutter-1 align-items-center justify-content-center">
                                                                    <div className="col-5">
                                                                        <Field name="hssRadial" type="number" step="any" className="form-control form-control--right" onBlur={(e) => {
                                                                            if (parseFloat(e.target.value) > 0) {
                                                                                model.hssRadial = parseFloat(e.target.value);                                                                                                             
                                                                                (model.hssRadial !== 0) ? setFieldValue('hssRadial', model.hssRadial.toFixed(2)) : setFieldValue('hssRadial', model.hssRadial.toFixed(0));
                                                                                this.setState({modified: true});
                                                                            }
                                                                            else {
                                                                                setFieldValue('hssRadial', 0);
                                                                                model.hssRadial = 0;
                                                                            }
                                                                        }} onFocus={(e) => {
                                                                            if (parseFloat(e.target.value) === 0) {
                                                                                setFieldValue('hssRadial', '');
                                                                            }
                                                                        }} onKeyDown={this.blockComma}>
                                                                        </Field>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Field name="hssradialunits" component="select" onChange={(e) => {                                                  
                                                                            setFieldValue('hssradialunits', e.target.value);
                                                                            model.hssRadialUnits = e.target.value;                                                                        
                                                                        }}>
                                                                            <option value="kN">kN</option>
                                                                            <option value="lb">lb</option>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gearbox-rotation gearbox-rotation--green js_gearbox-rotation">
                                                    <div className="row justify-content-center mb-4">
                                                        <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                            <div className="row gutter-1 align-items-center justify-content-center">
                                                                <div className="col-5 text-right">
                                                                    <label className="gearbox-rotation__label">Angle</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Field name='angle3' type="number" className="form-control gearbox-rotation__input js_gearbox-rotation-input" onChange={(e) => {
                                                                        model.angle3 = parseInt(e.target.value);
                                                                        if (model.angle3 > 180 || model.angle3 < -180) {
                                                                            model.angle3 = 0;
                                                                        }
                                                                        this.setState({ negativeAngle3 : -model.angle3})
                                                                        if (model.angle3 > 0) {
                                                                            this.setState({ transformedAngle3 : -model.angle3})
                                                                        } else {
                                                                            this.setState({ transformedAngle3 : -(180 - Math.abs(model.angle3)) })
                                                                        }
                                                                        setFieldValue('angle3', model.angle3);
                                                                    }} onFocus={(e) => {
                                                                        if (parseFloat(e.target.value) === 0) {
                                                                            setFieldValue('angle3', '');
                                                                        }
                                                                    }} onKeyDown={this.blockComma}/>
                                                                    <span className="gearbox-rotation__after-text">°</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gearbox-rotation__wheel" onMouseDown={(e) => { this.setState({ buttonPressed : true }) }}
                                                        onMouseUp={(e) => { this.setState({ buttonPressed : false }) }}
                                                        onMouseMove={(e) => {  
                                                            if (this.state.buttonPressed) {
                                                                const midpointX = (e.target.getBoundingClientRect().right - e.target.getBoundingClientRect().left) / 2.0;
                                                                const midpointY = (e.target.getBoundingClientRect().bottom - e.target.getBoundingClientRect().top) / 2.0;
                                                                
                                                                const vectorBegX = e.target.getBoundingClientRect().right - midpointX;
                                                                const vectorBegY = 0;
                                                                const vectorArrX = (e.clientX - e.target.getBoundingClientRect().left) - midpointX;
                                                                const vectorArrY = (e.clientY - e.target.getBoundingClientRect().top) - midpointY;
                                                                
                                                                const dot =  vectorBegX * vectorArrX + vectorBegY * vectorArrY;
                                                                const sc1 = Math.pow((Math.pow(vectorBegX, 2) + Math.pow(vectorBegY, 2)), 1/2);
                                                                const sc2 = Math.pow((Math.pow(vectorArrX, 2) + Math.pow(vectorArrY, 2)), 1/2);
                                                                                                            
                                                                const cosAngle = dot / (sc1 * sc2);
                                                                const angle3 = Math.round(Math.acos(cosAngle) * (180/Math.PI));
                                                                
                                                                if ((e.clientY - e.target.getBoundingClientRect().top) < midpointY) {
                                                                    model.angle3 = angle3;
                                                                } else {
                                                                    model.angle3 = -angle3;
                                                                }
                                                                this.setState({ negativeAngle3 : -model.angle3})
                                                                if (model.angle3 > 0) {
                                                                    this.setState({ transformedAngle3 : -model.angle3})
                                                                } else {
                                                                    this.setState({ transformedAngle3 : -(180 - Math.abs(model.angle3)) })
                                                                }
                                                                setFieldValue('angle3', model.angle3);
                                                            }
                                                        }} >                                                
                                                        <div className="gearbox-rotation__in js_in" style={{backgroundImage: model.angle3 > 0 ? "linear-gradient(transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle3 + "deg, rgb(114, 201, 139) 50%, transparent 50%)" : "linear-gradient(0deg, transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle3 + "deg, rgb(114, 201, 139) 50%, transparent 50%)"}}></div>                                            
                                                        <img className="gearbox-rotation__scale" src="images/svg/scale.svg" alt="Scale" />
                                                        <div className="gearbox-rotation__gear js_gear" style={{backgroundImage: (model.type === 'helical') ? "url('images/wingear/gears/" + model.shaftTypeHelical + "_c.png')" : "url('images/wingear/gears/" + model.shaftTypeBevel + "_c.png')"}}></div>
                                                        <img className="gearbox-rotation__pointer js_pointer" src="images/svg/pointer.svg" style={{transform: "rotate(" + this.state.negativeAngle3 + "deg)"}} alt="Pointer" />
                                                        <div className="gearbox-rotation__draggable-layer js_draggable-layer" style={{transform: "rotate(" + this.state.negativeAngle3 + "deg)"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <div className="row justify-content-center">
                                                        <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                            <label className="label d-block text-center mb-2">LSS radial force</label>
                                                            <div className="form-group__input-wrapper">
                                                                <div className="row gutter-1 align-items-center justify-content-center">
                                                                    <div className="col-5">
                                                                        <Field name="lssRadial" type="number" step="any" className="form-control form-control--right" onBlur={(e) => {
                                                                            if (parseFloat(e.target.value) > 0) {
                                                                                model.lssRadial = parseFloat(e.target.value);                                                                                                             
                                                                                (model.lssRadial !== 0) ? setFieldValue('lssRadial', model.lssRadial.toFixed(2)) : setFieldValue('lssRadial', model.lssRadial.toFixed(0));
                                                                                this.setState({modified: true});
                                                                            }
                                                                            else {
                                                                                setFieldValue('lssRadial', 0);
                                                                                model.hssRadial = 0;
                                                                            }
                                                                        }} onFocus={(e) => {
                                                                            if (parseFloat(e.target.value) === 0) {
                                                                                setFieldValue('lssRadial', '');
                                                                            }
                                                                        }} onKeyDown={this.blockComma}>
                                                                        </Field>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Field name="lssradialunits" component="select" onChange={(e) => {                                                  
                                                                            setFieldValue('lssradialunits', e.target.value);
                                                                            model.lssRadialUnits = e.target.value;                                                                        
                                                                        }}>
                                                                            <option value="kN">kN</option>
                                                                            <option value="lb">lb</option>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gearbox-rotation gearbox-rotation--green js_gearbox-rotation">
                                                    <div className="row justify-content-center mb-4">
                                                        <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                            <div className="row gutter-1 align-items-center justify-content-center">
                                                                <div className="col-5 text-right">
                                                                    <label className="gearbox-rotation__label">Angle</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Field name='angle4' type="number" className="form-control gearbox-rotation__input js_gearbox-rotation-input" onChange={(e) => {
                                                                        model.angle4 = parseInt(e.target.value);
                                                                        if (model.angle4 > 180 || model.angle4 < -180) {
                                                                            model.angle4 = 0;
                                                                        }
                                                                        this.setState({ negativeAngle4 : -model.angle4})
                                                                        if (model.angle4 > 0) {
                                                                            this.setState({ transformedAngle4 : -model.angle4})
                                                                        } else {
                                                                            this.setState({ transformedAngle4 : -(180 - Math.abs(model.angle4)) })
                                                                        }
                                                                        setFieldValue('angle4', model.angle4);
                                                                    }} onFocus={(e) => {
                                                                        if (parseFloat(e.target.value) === 0) {
                                                                            setFieldValue('angle4', '');
                                                                        }
                                                                    }} onKeyDown={this.blockComma}/>
                                                                    <span className="gearbox-rotation__after-text">°</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gearbox-rotation__wheel" onMouseDown={(e) => { this.setState({ buttonPressed : true }) }}
                                                        onMouseUp={(e) => { this.setState({ buttonPressed : false }) }}
                                                        onMouseMove={(e) => {  
                                                            if (this.state.buttonPressed) {
                                                                const midpointX = (e.target.getBoundingClientRect().right - e.target.getBoundingClientRect().left) / 2.0;
                                                                const midpointY = (e.target.getBoundingClientRect().bottom - e.target.getBoundingClientRect().top) / 2.0;
                                                                
                                                                const vectorBegX = e.target.getBoundingClientRect().right - midpointX;
                                                                const vectorBegY = 0;
                                                                const vectorArrX = (e.clientX - e.target.getBoundingClientRect().left) - midpointX;
                                                                const vectorArrY = (e.clientY - e.target.getBoundingClientRect().top) - midpointY;
                                                                
                                                                const dot =  vectorBegX * vectorArrX + vectorBegY * vectorArrY;
                                                                const sc1 = Math.pow((Math.pow(vectorBegX, 2) + Math.pow(vectorBegY, 2)), 1/2);
                                                                const sc2 = Math.pow((Math.pow(vectorArrX, 2) + Math.pow(vectorArrY, 2)), 1/2);
                                                                                                            
                                                                const cosAngle = dot / (sc1 * sc2);
                                                                const angle4 = Math.round(Math.acos(cosAngle) * (180/Math.PI));
                                                                
                                                                if ((e.clientY - e.target.getBoundingClientRect().top) < midpointY) {
                                                                    model.angle4 = angle4;
                                                                } else {
                                                                    model.angle4 = -angle4;
                                                                }
                                                                this.setState({ negativeAngle4 : -model.angle4})
                                                                if (model.angle4 > 0) {
                                                                    this.setState({ transformedAngle4 : -model.angle4})
                                                                } else {
                                                                    this.setState({ transformedAngle4 : -(180 - Math.abs(model.angle4)) })
                                                                }
                                                                setFieldValue('angle4', model.angle4);
                                                            }
                                                        }} >                                                
                                                        <div className="gearbox-rotation__in js_in" style={{backgroundImage: model.angle4 > 0 ? "linear-gradient(transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle4 + "deg, rgb(114, 201, 139) 50%, transparent 50%)" : "linear-gradient(0deg, transparent 50%, rgb(221, 229, 236) 50%), linear-gradient(" + this.state.transformedAngle4 + "deg, rgb(114, 201, 139) 50%, transparent 50%)"}}></div>                                            
                                                        <img className="gearbox-rotation__scale" src="images/svg/scale.svg" alt="Scale" />
                                                        <div className="gearbox-rotation__gear js_gear" style={{backgroundImage: (model.type === 'helical') ? "url('images/wingear/gears/" + model.shaftTypeHelical + "_d.png')" : "url('images/wingear/gears/" + model.shaftTypeBevel + "_d.png')"}}></div>
                                                        <img className="gearbox-rotation__pointer js_pointer" src="images/svg/pointer.svg" style={{transform: "rotate(" + this.state.negativeAngle4 + "deg)"}} alt="Pointer" />
                                                        <div className="gearbox-rotation__draggable-layer js_draggable-layer" style={{transform: "rotate(" + this.state.negativeAngle4 + "deg)"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gutter-5">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="row justify-content-center">
                                                    <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                        <label className="d-block text-center">HSS axial force</label>
                                                        <div className="row gutter-1 align-items-center justify-content-center">
                                                            <div className="col-5">
                                                                <Field name="hssAxial" type="number" step="any" className="form-control form-control--right" style={{width: "52px"}} onBlur={(e) => {
                                                                    if (parseFloat(e.target.value) > 0) {
                                                                        model.hssAxial = parseFloat(e.target.value);                                                                                                             
                                                                        (model.hssAxial !== 0) ? setFieldValue('hssAxial', model.hssAxial.toFixed(2)) : setFieldValue('hssAxial', model.hssAxial.toFixed(0));
                                                                        this.setState({modified: true});
                                                                    }
                                                                    else {
                                                                        setFieldValue('hssAxial', 0);
                                                                        model.hssAxial = 0;
                                                                    }
                                                                }} onFocus={(e) => {
                                                                    if (parseFloat(e.target.value) === 0) {
                                                                        setFieldValue('hssAxial', '');
                                                                    }
                                                                }} onKeyDown={this.blockComma}>
                                                                </Field>
                                                            </div>
                                                            <div className="col-5">
                                                                <Field name="hssaxialunits" component="select" onChange={(e) => {                                                  
                                                                    setFieldValue('hssaxialunits', e.target.value);
                                                                    model.hssAxialUnits = e.target.value;                                                                        
                                                                }}>
                                                                    <option value="kN">kN</option>
                                                                    <option value="lb">lb</option>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="row justify-content-center">
                                                    <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                        <label className="d-block text-center">LSS axial force</label>
                                                        <div className="row gutter-1 align-items-center justify-content-center">
                                                            <div className="col-5">
                                                                <Field name="lssAxial" type="number" step="any" className="form-control form-control--right" style={{width: "52px"}} onBlur={(e) => {
                                                                    if (parseFloat(e.target.value) > 0) {
                                                                        model.lssAxial = parseFloat(e.target.value);                                                                                                             
                                                                        (model.lssAxial !== 0) ? setFieldValue('lssAxial', model.lssAxial.toFixed(2)) : setFieldValue('lssAxial', model.lssAxial.toFixed(0));
                                                                        this.setState({modified: true});
                                                                    }
                                                                    else {
                                                                        setFieldValue('lssAxial', 0);
                                                                        model.lssAxial = 0;
                                                                    }
                                                                }} onFocus={(e) => {
                                                                    if (parseFloat(e.target.value) === 0) {
                                                                        setFieldValue('lssAxial', '');
                                                                    }
                                                                }} onKeyDown={this.blockComma}>
                                                                </Field>
                                                            </div>
                                                            <div className="col-5">
                                                                <Field name="lssaxialunits" component="select" style={{width: "55px"}} onChange={(e) => {                                                  
                                                                    setFieldValue('lssaxialunits', e.target.value);
                                                                    model.lssAxialUnits = e.target.value;                                                                        
                                                                }}>
                                                                    <option value="kN">kN</option>
                                                                    <option value="lb">lb</option>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gearbox-forces">
                                    {(model.type === 'bevel' && model.shaftTypeBevel.substring(1, 2) === 'T') ? (
                                        <img src={(model.hssAxialIn) ? "images/wingear/gears/" + model.shaftTypeBevel + "_e_in.png" : "images/wingear/gears/" + model.shaftTypeBevel + "_e_out.png"} className="gearbox-forces__image" style={{width: "160px", height: "120px"}} alt="Gearbox forces" />
                                    ) : (
                                        <img src={(model.type === 'helical') ? "images/wingear/gears/" + model.shaftTypeHelical + "_e.png" : "images/wingear/gears/" + model.shaftTypeBevel + "_e.png"} className="gearbox-forces__image" style={{width: "160px", height: "120px"}} alt="Gearbox forces" />
                                    )}
                                    <div className="row gutter-5">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="row justify-content-center">
                                                    <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                        <div className="row gutter-1 align-items-center justify-content-center">
                                                            <div className="col-10">
                                                                <div className="gearbox-forces__arrow-reverse gearbox-forces__arrow-reverse--1">
                                                                    <input type="checkbox" className="gearbox-forces__arrow-reverse-input" id="arrow-reverse-1" onChange={() => {
                                                                            model.hssAxialIn = !model.hssAxialIn;
                                                                            setFieldValue('arrow-reverse-1', model.hssAxialIn);                                                
                                                                        }} />
                                                                    <label className="gearbox-forces__arrow-reverse-label" htmlFor="arrow-reverse-1">
                                                                        <button className="gearbox-forces__btn">
                                                                            <svg className="gearbox-forces__btn-icon"><use xlinkHref="images/svg/svg-sprite.svg#arrows"></use></svg>
                                                                        </button>
                                                                        {(model.type === 'bevel' && model.shaftTypeBevel.substring(1, 2) === 'T') ? '' : (
                                                                                <span className="gearbox-forces__arrow" style={
                                                                                    (model.type === 'helical') ?
                                                                                        (model.shaftTypeHelical.substring(1, 2) === 'R') ? {transform: "translateX(82px) translateY(75px) rotateZ(-90deg)"} :
                                                                                        {transform: "translateX(82px) translateY(-65px) rotateZ(90deg)"} 
                                                                                    :
                                                                                        {transform: "translateY(5px)"}                                                        
                                                                                    }></span>
                                                                            )}
                                                                    </label>
                                                                    <div className="help gearbox-forces__help" data-toggle="popover" title="" data-placement="top" data-content="<img src='images/wingear/Help_Force_direction3.png' />" tabIndex="0" data-original-title="HSS Axial force" style={{display: (model.type === 'bevel') ? (model.shaftTypeBevel.substring(1, 2) === 'T') ? 'block' : 'none' : 'none' }}>
                                                                        <svg className="help__icon"><use xlinkHref="images/svg/svg-sprite.svg#question-mark"></use></svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className="row justify-content-center">
                                                    <div className="col-10 col-sm-7 col-md-8 col-lg-10">
                                                        <div className="row gutter-1 align-items-center justify-content-center">
                                                            <div className="col-10">
                                                                <div className="gearbox-forces__arrow-reverse gearbox-forces__arrow-reverse--2">
                                                                    <input type="checkbox" className="gearbox-forces__arrow-reverse-input" id="arrow-reverse-2" onClick={() => {
                                                                            model.lssAxialIn = !model.lssAxialIn;                                                    
                                                                        }}/>
                                                                    <label className="gearbox-forces__arrow-reverse-label" htmlFor="arrow-reverse-2">
                                                                        <button className="gearbox-forces__btn">
                                                                            <svg className="gearbox-forces__btn-icon"><use xlinkHref="images/svg/svg-sprite.svg#arrows"></use></svg>
                                                                        </button>
                                                                        <span className="gearbox-forces__arrow" style={
                                                                                (model.type === 'helical') ?
                                                                                    (model.shaftTypeHelical.substring(2, 3) === 'R') ? {transform: "translateY(175px) translateX(5px) rotateZ(-90deg)"} :
                                                                                    (model.shaftTypeHelical.substring(2, 3) === 'H') ? {transform: "translateY(30px) translateX(5px) rotateZ(90deg)"} :
                                                                                    {transform: "translateX(5px) rotateZ(90deg)"} 
                                                                                :
                                                                                    (model.shaftTypeBevel.substring(2, 3) === 'L') ? {transform: "translateX(5px) rotateZ(90deg)"} :
                                                                                    (model.shaftTypeBevel.substring(2, 3) === 'H') ? {transform: "translateY(30px) translateX(5px) rotateZ(90deg)"} :
                                                                                    (model.shaftTypeBevel.substring(2, 3) === 'D') ? {transform: "translateX(5px) rotateZ(90deg)"} :
                                                                                    (model.shaftTypeBevel.substring(2, 3) === 'R') ? {transform: "translateY(175px) translateX(5px) rotateZ(-90deg)"} :
                                                                                    {transform: "translateX(80px) translateY(78px) rotateZ(90deg)"}
                                                                            }></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div style={{ display: "block", height: "50px" }}></div>
                                </div>
                            </div>                       
                        </div> 
                    </div>                   
                </div>
                <div className="col-md-6 col-lg-4">                        
                {(this.state.summary === false) ? (
                    <div className="summary summary--has-overlay">
                        <button name="calculate" type="submit" className={(model.gearboxParams.ratio <= 0 || model.ka <= 0 || model.gearboxParams.pwr <= 0 || model.gearboxParams.hst <= 0 || model.gearboxParams.lst <= 0 || this.checkInvalidRatio() === true || (this.state.externalCooling === false && (this.state.listDutyCycle === 0 || this.state.listAmbientTemperature === 0 || this.state.listUtilization === 0 || this.state.listAirFlow === 0))) ? 'btn btn--secondary btn--disabled summary__overlay-btn d-none d-md-inline-block' : 'btn btn--secondary summary__overlay-btn d-none d-md-inline-block'} disabled={this.state.stop}>Calculate</button>
                        <div className="summary__section summary__section--1">
                            <h3 className="summary__headline">Gearbox size and ratio</h3>
                        </div>

                        <div className="summary__section">
                            <h3 className="summary__headline summary__headline--has-help">
                                <span>Specification</span>                           
                            </h3>
                            <table className="summary__table">
                                <tbody>
                                    <tr>
                                        <td>Power</td>
                                        <td className="text-right"><b>-</b></td>
                                        <td>Nm</td>
                                    </tr>
                                    <tr>
                                        <td>Low speed shaft</td>
                                        <td className="text-right"><b>-</b></td>
                                        <td>RPM</td>
                                    </tr>
                                    <tr>
                                        <td>High speed shaft </td>
                                        <td className="text-right"><b>-</b></td>
                                        <td>RPM</td>
                                    </tr>
                                    <tr>
                                        <td>Output shaft torque</td>
                                        <td className="text-right"><b>-</b></td>
                                        <td>Nm</td>
                                    </tr>
                                    <tr>
                                        <td>Gearbox rated torque</td>
                                        <td className="text-right"><b>-</b></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="summary__blueprint"></div>
                            {(!this.state.showNotes) ? (
                            <div className="btn-simple-toolbar">
                                <span className="btn-simple btn-simple--small" id="js_add-notes" onClick={() => {
                                    this.setState({showNotes: true})
                                }}>
                                    <svg className="btn-simple__icon"><use xlinkHref="images/svg/svg-sprite.svg#plus-circle"></use></svg>
                                    Add your notes
                                </span>
                            </div>
                            ) : (                        
                            <div className="summary__notes" id="js_notes" style={{display: 'block'}}>
                                <textarea className="summary__notes__textarea" onkeyup="textAreaAdjust(this)" onChange={(e) => {
                                    model.notes = this.filterText(e.target.value);
                                }}></textarea>
                            </div>
                            )}
                        </div>
                        <div className="summary__section">
                            <div className="accordion" id="accordion-1">
                                <div className="accordion__card">
                                    <div className="accordion__card-header collapsed" data-toggle="collapse" data-target="#card-1" aria-expanded="true" aria-controls="card-1" onClick={() => {
                                        const currentState = this.state.accessories;
                                        this.setState({accessories: !currentState});
                                    }}>
                                        Requested additional accessories
                                    </div>
                                    <div id="card-1" className={(this.state.accessories) ? "collapse show" : "collapse"} data-parent="#accordion-1" aria-labelledby="heading-1">
                                        <div className="accordion__card-body">
                                            <div className="form-group form-group--mb-8">
                                                <div className="form-group__input-wrapper form-group__input-wrapper--custom-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="lubricationunit-checkbox" onChange={() => {
                                                                model.lubricationUnit = !model.lubricationUnit;
                                                                setFieldValue('lubricationunit-checkbox', model.lubricationUnit);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="lubricationunit-checkbox">Lubrication unit</label>
                                                    </div>
                                                    <div className="switch" style={{transform: "translateY(1px)"}}>
                                                        <input type="checkbox" className="switch__input" id="switch-3" checked={!model.coolingByWater} onChange={() => {
                                                            model.coolingByWater = !model.coolingByWater;  
                                                            setFieldValue('switch-3', model.coolingByWater);  
                                                        }} />
                                                        <label className="switch__label" htmlFor="switch-3">
                                                            <span className="switch__text" style={(!model.coolingByWater) ? {fontWeight: "bold"} : {fontWeight: "normal"}}>Cooling by air</span>
                                                            <span className="switch__button"></span>
                                                            <span className="switch__text" style={(model.coolingByWater) ? {fontWeight: "bold"} : {fontWeight: "normal"}}>Water</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row gutter-5 align-items-center">
                                                    <div className="col-6 col-sm-7">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="hsscoupling-checkbox" checked={model.highspeedCoupling} onChange={() => {
                                                                model.highspeedCoupling = !model.highspeedCoupling;
                                                                setFieldValue('hsscoupling-checkbox', model.highspeedCoupling);
                                                                if (model.highspeedCoupling === false) {
                                                                    model.highspeedCouplingType = 0;
                                                                    setFieldValue('highspeedcoupling', 0);
                                                                }
                                                            }} />
                                                            <label className="form-check-label" htmlFor="hsscoupling-checkbox">High speed coupling</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-5">
                                                        <div className="form-group__input-wrapper">
                                                            <Field name="highspeedcoupling" component="select" onChange={(e) => {
                                                                setFieldValue('highspeedcoupling', e.target.value);
                                                                model.highspeedCouplingType = e.target.value;
                                                                if (e.target.value !== 0) {
                                                                    model.highspeedCoupling = true;
                                                                    setFieldValue('hsscoupling-checkbox', model.highspeedCoupling);
                                                                } else {
                                                                    model.highspeedCoupling = false;
                                                                    setFieldValue('hsscoupling-checkbox', model.highspeedCoupling);
                                                                }
                                                            }}>
                                                                <option value="0">No preference</option>
                                                                {this.state.highspeedCoupling_options}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group form-group--mb-4">
                                                <div className="row gutter-5 align-items-center">
                                                    <div className="col-6 col-sm-7">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="lsscoupling-checkbox" checked={model.lowspeedCoupling} onChange={() => {
                                                                model.lowspeedCoupling = !model.lowspeedCoupling;
                                                                setFieldValue('lsscoupling-checkbox', model.lowspeedCoupling);
                                                                if (model.lowspeedCoupling === false) {
                                                                    model.lowspeedCouplingType = 0;
                                                                    setFieldValue('lowspeedcoupling', 0);
                                                                }
                                                            }} />
                                                            <label className="form-check-label" htmlFor="lsscoupling-checkbox">Low speed coupling</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-5">
                                                        <div className="form-group__input-wrapper">
                                                            <Field name="lowspeedcoupling" component="select" onChange={(e) => {
                                                                setFieldValue('lowspeedcoupling', e.target.value);
                                                                model.lowspeedCouplingType = e.target.value;
                                                                if (e.target.value !== 0) {
                                                                    model.lowspeedCoupling = true;
                                                                    setFieldValue('lsscoupling-checkbox', model.lowspeedCoupling);
                                                                } else {
                                                                    model.lowspeedCoupling = false;
                                                                    setFieldValue('lsscoupling-checkbox', model.lowspeedCoupling);
                                                                }
                                                            }}>
                                                                <option value="0">No preference</option>
                                                                {this.state.lowspeedCoupling_options}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="backstop-checkbox" onChange={() => {
                                                                model.backStop = !model.backStop;
                                                                setFieldValue('backstop-checkbox', model.backStop);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="backstop-checkbox">Back stop</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="safetybrake-checkbox" onChange={() => {
                                                                model.safetyBrake = !model.safetyBrake;
                                                                setFieldValue('safetybrake-checkbox', model.safetyBrake);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="safetybrake-checkbox">Safety brake</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="backstop-checkbox" onChange={() => {
                                                                model.electricMotor = !model.electricMotor;
                                                                setFieldValue('backstop-checkbox', model.electricMotor);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="backstop-checkbox">Electric motor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="frequencyconvertor-checkbox" onChange={() => {
                                                                model.frequencyConvertor = !model.frequencyConvertor;
                                                                setFieldValue('frequencyconvertor-checkbox', model.frequencyConvertor);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="frequencyconvertor-checkbox">Frequency convertor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="flangemotor-checkbox" onChange={() => {
                                                                model.flangeMotor = !model.flangeMotor;
                                                                setFieldValue('flangemotor-checkbox', model.flangeMotor);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="flangemotor-checkbox">Flange for electric motor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="coutputflange-checkbox" onChange={() => {
                                                                model.outputFlange = !model.outputFlange;
                                                                setFieldValue('outputflange-checkbox', model.outputFlange);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="outputflange-checkbox">Output flange</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="inputcooling-checkbox" onChange={() => {
                                                                model.inputCooling = !model.inputCooling;
                                                                setFieldValue('inputcooling-checkbox', model.inputCooling);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="inputcooling-checkbox">Input cooling fan with cover</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="torquesupport-checkbox" onChange={() => {
                                                                model.torqueSupport = !model.torqueSupport;
                                                                setFieldValue('torquesupport-checkbox', model.torqueSupport);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="torquesupport-checkbox">Torque support</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="note mt-3">*diagnostic equipment please specify in notes</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
            ) : (                
                    <div className={(this.state.modified === false) ? "summary" : "summary summary--has-overlay"}>                    
                    {(this.state.modified === true) ? (
                    <button name="calculate" type="submit" className={(model.gearboxParams.ratio <= 0 || model.ka <= 0 || model.gearboxParams.pwr <= 0 || model.gearboxParams.hst <= 0 || model.gearboxParams.lst <= 0 || this.checkInvalidRatio() === true || (this.state.externalCooling === false && (this.state.listDutyCycle === 0 || this.state.listAmbientTemperature === 0 || this.state.listUtilization === 0 || this.state.listAirFlow === 0))) ? "btn btn--secondary btn--disabled summary__overlay-btn" : "btn btn--secondary summary__overlay-btn d-none d-md-inline-block" } disabled={this.state.stop}>Recalculate</button>
                    ) : "" }                
                        <div className="summary__section summary__section--1">
                            <h3 className="summary__headline">Gearbox size and ratio</h3>
                            <div className="form-group__input-wrapper">
                                {(model.gearboxes.optimal.size !== 0) ? (
                                <div className="form-check summary__check">
                                    <input className="form-check-input" type="radio" name="radiogroup-4" id="radio-4-1" checked={(this.state.selected === 1) ? true : false} onChange={(e) => {
                                        this.setState({
                                            selected: 1,
                                            gearbox: model.gearboxes.optimal,
                                            schemaUrl: this.getSchemaUrl(model.gearboxes.optimal),
                                            modelUrl: this.getModelUrl(model.gearboxes.optimal)
                                        })
                                        setFieldValue('radiogroup-4', this.state.selected);
                                    }}/>
                                    <label className="form-check-label summary__check-label" htmlFor="radio-4-1">
                                        Option {this.state.names[indexNames++ % 4]}:
                                        <span className="float-right">KA = {parseFloat(model.gearboxes.optimal.ka).toFixed(2)}</span>
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">size:</span> {parseFloat(model.gearboxes.optimal.size).toFixed(0)}
                                            </span>
                                            
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio:</span> {parseFloat(model.gearboxes.optimal.trueRatio).toFixed(2)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio deviation:</span> {parseFloat(model.gearboxes.optimal.deviation).toFixed(2)} %
                                            </span>
                                        </span>
                                        <span className="summary__check-label-info">
                                            {(!this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">cooling factor:</span> {parseFloat(model.gearboxes.optimal.coolingFactor).toFixed(2)}
                                            </span>
                                            ) : "" }
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">bearing life L10:</span> {this.getBearingLife(parseFloat(model.gearboxes.optimal.bearingLife).toFixed(0))}&nbsp;h
                                            </span>
                                            {(this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.optimal.weight))}&nbsp;kg                                                
                                            </span>
                                            ) : "" }
                                        </span>
                                        {(!this.state.externalCooling) ? (
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.optimal.weight))}&nbsp;kg                                                
                                            </span>
                                        </span>
                                        ) : "" }
                                    </label>
                                </div>
                                ) : "" }
                                {(model.gearboxes.optimalSecond.size !== 0) ? (
                                <div className="form-check summary__check">
                                    <input className="form-check-input" type="radio" name="radiogroup-4" id="radio-4-2" checked={(this.state.selected === 2) ? true : false} onChange={(e) => {
                                        this.setState({
                                            selected: 2,
                                            gearbox: model.gearboxes.optimalSecond,
                                            schemaUrl: this.getSchemaUrl(model.gearboxes.optimalSecond),
                                            modelUrl: this.getModelUrl(model.gearboxes.optimalSecond)
                                        })
                                        setFieldValue('radiogroup-4', this.state.selected);
                                    }}/>
                                    <label className="form-check-label summary__check-label" htmlFor="radio-4-2">
                                        Option {this.state.names[indexNames++ % 4]}:
                                        <span className="float-right">KA = {parseFloat(model.gearboxes.optimalSecond.ka).toFixed(2)}</span>
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">size:</span> {parseFloat(model.gearboxes.optimalSecond.size).toFixed(0)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio:</span> {parseFloat(model.gearboxes.optimalSecond.trueRatio).toFixed(2)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio deviation:</span> {parseFloat(model.gearboxes.optimalSecond.deviation).toFixed(2)} %
                                            </span>
                                        </span>
                                        <span className="summary__check-label-info">
                                            {(!this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">cooling factor:</span> {parseFloat(model.gearboxes.optimalSecond.coolingFactor).toFixed(2)}
                                            </span>
                                            ) : "" }
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">bearing life L10:</span> {this.getBearingLife(parseFloat(model.gearboxes.optimalSecond.bearingLife).toFixed(0))}&nbsp;h
                                            </span>
                                            {(this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.optimalSecond.weight))}&nbsp;kg
                                            </span>
                                            ) : "" }
                                        </span>
                                        {(!this.state.externalCooling) ? (
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.optimalSecond.weight))}&nbsp;kg
                                            </span>
                                        </span>
                                        ) : "" }
                                    </label>
                                </div>
                                ) : ""}
                                {(model.gearboxes.suboptimal.size !== 0) ? (
                                <div className="form-check summary__check">
                                    <input className="form-check-input" type="radio" name="radiogroup-4" id="radio-4-3" checked={(this.state.selected === 3) ? true : false} onChange={(e) => {
                                        this.setState({
                                            selected: 3,
                                            gearbox: model.gearboxes.suboptimal,
                                            schemaUrl: this.getSchemaUrl(model.gearboxes.suboptimal),
                                            modelUrl: this.getModelUrl(model.gearboxes.suboptimal)
                                        })
                                        setFieldValue('radiogroup-4', this.state.selected);
                                    }}/>
                                    <label className="form-check-label summary__check-label" htmlFor="radio-4-3">
                                        Option {this.state.names[indexNames++ % 4]}:
                                        <span className="float-right">KA = {parseFloat(model.gearboxes.suboptimal.ka).toFixed(2)}</span>
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">size:</span> {parseFloat(model.gearboxes.suboptimal.size).toFixed(0)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio:</span> {parseFloat(model.gearboxes.suboptimal.trueRatio).toFixed(2)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio deviation:</span> {parseFloat(model.gearboxes.suboptimal.deviation).toFixed(2)} %
                                            </span>
                                        </span>
                                        <span className="summary__check-label-info">
                                            {(!this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">cooling factor:</span> {parseFloat(model.gearboxes.suboptimal.coolingFactor).toFixed(2)}
                                            </span>
                                            ) : "" }
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">bearing life L10:</span> {this.getBearingLife(parseFloat(model.gearboxes.suboptimal.bearingLife).toFixed(0))}&nbsp;h
                                            </span>
                                            {(this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.suboptimal.weight))}&nbsp;kg
                                            </span>
                                            ) : "" }
                                        </span>
                                        {(!this.state.externalCooling) ? (
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.suboptimal.weight))}&nbsp;kg
                                            </span>
                                        </span>
                                        ) : "" }
                                    </label>
                                </div>
                                ) : ""}
                                {(model.gearboxes.suboptimalSecond.size !== 0) ? (
                                <div className="form-check summary__check">
                                    <input className="form-check-input" type="radio" name="radiogroup-4" id="radio-4-4" checked={(this.state.selected === 4) ? true : false} onChange={(e) => {
                                        this.setState({
                                            selected: 4,
                                            gearbox: model.gearboxes.suboptimalSecond,
                                            schemaUrl: this.getSchemaUrl(model.gearboxes.suboptimalSecond),
                                            modelUrl: this.getModelUrl(model.gearboxes.suboptimalSecond)
                                        })
                                        setFieldValue('radiogroup-4', this.state.selected);
                                    }}/>
                                    <label className="form-check-label summary__check-label" htmlFor="radio-4-4">
                                        Option {this.state.names[indexNames++ % 4]}:
                                        <span className="float-right">KA = {parseFloat(model.gearboxes.suboptimalSecond.ka).toFixed(2)}</span>
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">size:</span> {parseFloat(model.gearboxes.suboptimalSecond.size).toFixed(0)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio:</span> {parseFloat(model.gearboxes.suboptimalSecond.trueRatio).toFixed(2)}
                                            </span>
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">ratio deviation:</span> {parseFloat(model.gearboxes.suboptimalSecond.deviation).toFixed(2)} %
                                            </span>
                                        </span>
                                        <span className="summary__check-label-info">
                                            {(!this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">cooling factor:</span> {parseFloat(model.gearboxes.suboptimalSecond.coolingFactor).toFixed(2)}
                                            </span>
                                            ) : "" }
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">bearing life L10:</span> {this.getBearingLife(parseFloat(model.gearboxes.suboptimalSecond.bearingLife).toFixed(0))}&nbsp;h
                                            </span>
                                            {(this.state.externalCooling) ? (
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.suboptimalSecond.weight))}&nbsp;kg
                                            </span>
                                            ) : "" }
                                        </span>
                                        {(!this.state.externalCooling) ? (
                                        <span className="summary__check-label-info">
                                            <span className="summary__check-label-value">
                                                <span className="summary__check-label-value-name">weight:</span> {this.formatNumber(parseFloat(model.gearboxes.suboptimalSecond.weight))}&nbsp;kg
                                            </span>
                                        </span>
                                        ) : "" }
                                    </label>
                                </div>
                                ) : "" }
                            </div>
                        </div>

                        <div className="summary__section">
                            <h3 className="summary__headline summary__headline--has-help">
                                <span>Specification</span>
                            </h3>
                            <table className="summary__table">
                                <tbody>
                                    <tr>
                                        <td>Power</td>
                                        {(model.fixedParam === 'pwr' || model.fixedParam === 'hst') ? (                                            
                                        <td className="text-right"><b>{(parseFloat(model.gearboxParams.pwr).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></td>
                                        ) : null }
                                        {(model.fixedParam === 'lst') ? (
                                        <td className="text-right"><b>
                                            {(parseInt(model.unitspwr.current) === 1) ? 
                                            parseFloat((2 * 3.141592 * ((model.gearboxParams.lst * model.unitslst.knm) / this.state.gearbox.trueRatio) * model.gearboxParams.hss) / 60 * model.unitspwr.kw).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            parseFloat((2 * 3.141592 * ((model.gearboxParams.lst * model.unitslst.knm) / this.state.gearbox.trueRatio) * model.gearboxParams.hss) / 60 * model.unitspwr.hp).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }
                                            </b></td>
                                        ) : null }
                                        <td>{(parseInt(model.unitspwr.current) === 1) ? "kW" : "hp"}</td>
                                    </tr>
                                    <tr>
                                        <td>Low speed shaft</td>
                                        <td className="text-right"><b>{parseFloat(this.state.gearbox.lss).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></td>
                                        <td>RPM</td>
                                    </tr>
                                    <tr>
                                        <td>High speed shaft </td>
                                        <td className="text-right"><b>{parseFloat(model.gearboxParams.hss).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></td>
                                        <td>RPM</td>
                                    </tr>
                                    <tr>
                                        <td>Lowspeed shaft torque</td>
                                        {(model.fixedParam === 'pwr') ? (
                                        <td className="text-right"><b>{(parseInt(model.unitslst.current) === 1) ? parseFloat(((60 * model.gearboxParams.pwr * model.unitspwr.kw * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)) * 1000.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                                                                        (parseInt(model.unitslst.current) === 2) ? parseFloat((60 * model.gearboxParams.pwr * model.unitspwr.kw * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(((60 * model.gearboxParams.pwr * model.unitspwr.kw * this.state.gearbox.trueRatio) / (2 * 3.141592 * model.gearboxParams.hss)) * 737.562).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</b></td>
                                        ) : null }
                                        {(model.fixedParam === 'lst') ? (
                                        <td className="text-right"><b>{(parseInt(model.unitslst.current) === 1) ? parseFloat(model.gearboxParams.lst * model.unitslst.nm).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (parseInt(model.unitslst.current) === 2) ? parseFloat(model.gearboxParams.lst * model.unitslst.knm).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(model.gearboxParams.lst * model.unitslst.ftlb).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</b></td>
                                        )  : null }
                                        {(model.fixedParam === 'hst') ? (
                                        <td className="text-right"><b>{(parseInt(model.unitslst.current) === 1) ? parseFloat(model.gearboxParams.hst * model.unitshst.knm * this.state.gearbox.trueRatio * 1000.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (parseInt(model.unitslst.current) === 2) ? parseFloat(model.gearboxParams.hst * model.unitshst.knm * this.state.gearbox.trueRatio).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(model.gearboxParams.hst * model.unitshst.knm * this.state.gearbox.trueRatio * 737.562).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</b></td>
                                        ) : null }
                                        <td>{(parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft" }</td>
                                    </tr>
                                    <tr>
                                        <td>Gearbox rated torque</td>
                                        <td className="text-right"><b>{(parseInt(model.unitslst.current) === 1) ? parseFloat(this.state.gearbox.ratedTorque * 1000.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (parseInt(model.unitslst.current) === 2) ? parseFloat(this.state.gearbox.ratedTorque).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(this.state.gearbox.ratedTorque * 737.562).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</b></td>
                                        <td>{(parseInt(model.unitslst.current) === 1) ? "Nm" : (parseInt(model.unitslst.current) === 2) ? "kNm" : "lb-ft" }</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="summary__blueprint summary__blueprint--has-image" data-toggle="modal" data-target="#image-modal">
                                <div className="summary__blueprint__image" style={{backgroundImage: "url('images/wingear/schemas/" + this.state.schemaUrl + "')"}}></div>
                                <svg className="summary__blueprint-icon"><use xlinkHref="images/svg/svg-sprite.svg#enlarge"></use></svg>
                            </div>
                            {(!this.state.showNotes) ? (
                            <div className="btn-simple-toolbar">
                                <span className="btn-simple btn-simple--small" id="js_add-notes" onClick={() => {
                                    this.setState({showNotes: true})
                                }}>
                                    <svg className="btn-simple__icon"><use xlinkHref="images/svg/svg-sprite.svg#plus-circle"></use></svg>
                                    Add your notes
                                </span>
                            </div>
                            ) : (                        
                            <div className="summary__notes" id="js_notes" style={{display: 'block'}}>
                                <textarea className="summary__notes__textarea" onkeyup="textAreaAdjust(this)" onChange={(e) => {
                                    model.notes = this.filterText(e.target.value);
                                }}></textarea>
                            </div>
                            )}
                        </div>
                        <div className="summary__section">
                            <div className="accordion" id="accordion-1">
                                <div className="accordion__card">
                                    <div className="accordion__card-header collapsed" data-toggle="collapse" data-target="#card-1" aria-expanded="true" aria-controls="card-1" onClick={() => {
                                        const currentState = this.state.accessories;
                                        this.setState({accessories: !currentState});
                                    }}>
                                        Requested additional accessories
                                    </div>
                                    <div id="card-1" className={(this.state.accessories) ? "collapse show" : "collapse"} data-parent="#accordion-1" aria-labelledby="heading-1">
                                        <div className="accordion__card-body">
                                            <div className="form-group form-group--mb-8">
                                                <div className="form-group__input-wrapper form-group__input-wrapper--custom-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="lubricationunit-checkbox" onChange={() => {
                                                                model.lubricationUnit = !model.lubricationUnit;
                                                                setFieldValue('lubricationunit-checkbox', model.lubricationUnit);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="lubricationunit-checkbox">Lubrication unit</label>
                                                    </div>
                                                    <div className="switch" style={{transform: "translateY(1px)"}}>
                                                        <input type="checkbox" className="switch__input" id="switch-3" checked={!model.coolingByWater} onChange={() => {
                                                            model.coolingByWater = !model.coolingByWater;
                                                            setFieldValue('switch-3', model.coolingByWater);
                                                        }} />
                                                        <label className="switch__label" htmlFor="switch-3">
                                                            <span className="switch__text" style={(!model.coolingByWater) ? {fontWeight: "bold"} : {fontWeight: "normal"}}>Cooling by air</span>
                                                            <span className="switch__button"></span>
                                                            <span className="switch__text" style={(model.coolingByWater) ? {fontWeight: "bold"} : {fontWeight: "normal"}}>Water</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row gutter-5 align-items-center">
                                                    <div className="col-6 col-sm-7">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="hsscoupling-checkbox" checked={model.highspeedCoupling} onChange={() => {
                                                                model.highspeedCoupling = !model.highspeedCoupling;
                                                                setFieldValue('hsscoupling-checkbox', model.highspeedCoupling);
                                                                if (model.highspeedCoupling === false) {
                                                                    model.highspeedCouplingType = 0;
                                                                    setFieldValue('highspeedcoupling', 0);
                                                                }
                                                            }} />
                                                            <label className="form-check-label" htmlFor="hsscoupling-checkbox">High speed coupling</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-5">
                                                        <div className="form-group__input-wrapper">
                                                            <Field name="highspeedcoupling" component="select" onChange={(e) => {
                                                                setFieldValue('highspeedcoupling', e.target.value);
                                                                model.highspeedCouplingType = e.target.value;
                                                                if (parseInt(e.target.value) !== 0) {
                                                                    console.log('je to tu')
                                                                    model.highspeedCoupling = true;
                                                                    setFieldValue('hsscoupling-checkbox', model.highspeedCoupling);
                                                                } else {
                                                                    model.highspeedCoupling = false;
                                                                    setFieldValue('hsscoupling-checkbox', model.highspeedCoupling);
                                                                }
                                                            }}>
                                                                <option value="0">No preference</option>
                                                                {this.state.highspeedCoupling_options}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group form-group--mb-4">
                                                <div className="row gutter-5 align-items-center">
                                                    <div className="col-6 col-sm-7">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="lsscoupling-checkbox" checked={model.lowspeedCoupling} onChange={() => {
                                                                model.lowspeedCoupling = !model.lowspeedCoupling;
                                                                setFieldValue('lsscoupling-checkbox', model.lowspeedCoupling);
                                                                if (model.lowspeedCoupling === false) {
                                                                    model.lowspeedCouplingType = 0;
                                                                    setFieldValue('lowspeedcoupling', 0);
                                                                }
                                                            }} />
                                                            <label className="form-check-label" htmlFor="lsscoupling-checkbox">Low speed coupling</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-5">
                                                        <div className="form-group__input-wrapper">
                                                            <Field name="lowspeedcoupling" component="select" onChange={(e) => {
                                                                setFieldValue('lowspeedcoupling', e.target.value);
                                                                model.lowspeedCouplingType = e.target.value;
                                                                if (parseInt(e.target.value) !== 0) {
                                                                    model.lowspeedCoupling = true;
                                                                    setFieldValue('lsscoupling-checkbox', model.lowspeedCoupling);
                                                                } else {
                                                                    model.lowspeedCoupling = false;
                                                                    setFieldValue('lsscoupling-checkbox', model.lowspeedCoupling);
                                                                }
                                                            }}>
                                                                <option value="0">No preference</option>
                                                                {this.state.lowspeedCoupling_options}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="backstop-checkbox" onChange={() => {
                                                                model.backStop = !model.backStop;
                                                                setFieldValue('backstop-checkbox', model.backStop);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="backstop-checkbox">Back stop</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="safetybrake-checkbox" onChange={() => {
                                                                model.safetyBrake = !model.safetyBrake;
                                                                setFieldValue('safetybrake-checkbox', model.safetyBrake);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="safetybrake-checkbox">Safety brake</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="backstop-checkbox" onChange={() => {
                                                                model.electricMotor = !model.electricMotor;
                                                                setFieldValue('backstop-checkbox', model.electricMotor);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="backstop-checkbox">Electric motor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="frequencyconvertor-checkbox" onChange={() => {
                                                                model.frequencyConvertor = !model.frequencyConvertor;
                                                                setFieldValue('frequencyconvertor-checkbox', model.frequencyConvertor);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="frequencyconvertor-checkbox">Frequency convertor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="flangemotor-checkbox" onChange={() => {
                                                                model.flangeMotor = !model.flangeMotor;
                                                                setFieldValue('flangemotor-checkbox', model.flangeMotor);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="flangemotor-checkbox">Flange for electric motor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="coutputflange-checkbox" onChange={() => {
                                                                model.outputFlange = !model.outputFlange;
                                                                setFieldValue('outputflange-checkbox', model.outputFlange);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="outputflange-checkbox">Output flange</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="inputcooling-checkbox" onChange={() => {
                                                                model.inputCooling = !model.inputCooling;
                                                                setFieldValue('inputcooling-checkbox', model.inputCooling);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="inputcooling-checkbox">Input cooling fan with cover</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group__input-wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="torquesupport-checkbox" onChange={() => {
                                                                model.torqueSupport = !model.torqueSupport;
                                                                setFieldValue('torquesupport-checkbox', model.torqueSupport);
                                                            }} />
                                                        <label className="form-check-label" htmlFor="torquesupport-checkbox">Torque support</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="note mt-3">*diagnostic equipment please specify in notes</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="summary__section summary__section--2">
                        <div className="text-center mb-4">
                                <button className="btn btn--primary" data-toggle="modal" data-target="#request-quotation" onClick={() => {this.setState({isSubmitted: false})}}>Request quotation</button>
                            </div>
                            <div className="btn-simple-toolbar">
                                <div className="btn-simple" onClick={this.handlePdf.bind(this)}>
                                    <svg className="btn-simple__icon"><use xlinkHref="images/svg/svg-sprite.svg#pdf"></use></svg>
                                    Generate PDF report
                                </div>                                
                            </div>
                        </div>
                    </div>
                )}
                </div>
        </div>

        <div className="modal fade image-modal" id="image-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content image-modal__content">
                    <div className="image-modal__close" data-dismiss="modal">
                        <svg className="image-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                    </div>
                    <img src={'images/wingear/schemas/' + this.state.schemaUrl} className="image-modal__image" alt="Schema" />
                </div>
            </div>
        </div>

        <div className="modal fade form-modal" id="request-quotation" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered form-modal__dialog" role="document">
            {(!this.state.isSubmitted) ? (
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Quote request
                        </div>
                        <div className="form-modal__close" data-dismiss="modal">
                            <svg className="form-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <div className={(this.state.quoteStop) ? "form-group form-group--has-error" : "form-group" }>
                            <div className="row gutter-5 align-items-center">
                                <div className="col-md-4 mb-2 mb-md-0">
                                    <label className="label">Your e-mail address *</label>
                                </div>
                                <div className="col-md-8 mb-2 mb-md-0">
                                    <div className="form-group__input-wrapper">
                                        <Field name="email" type="email" className="form-control" onChange={(e) => {
                                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(e.target.value)) {
                                            this.setState({ quoteStop: true });
                                        }
                                        else {
                                            this.setState({ quoteStop: false, email: this.filterText(e.target.value) });
                                        }
                                        }} />
                                        {(this.state.quoteStop) ? <div className="form-group__warning-sign js_warning-sign" data-target="#alert-2" onClick={() => {
                                            this.setState({ warningEmail: true });
                                        }}></div> : "" }
                                    </div>
                                </div>
                            </div>
                            {(this.state.warningEmail) ? 
                            <div className="alert alert-error js_alert" role="alert" id="alert-2">
                                The format of e-mail is incorrect.
                                <button type="button" className="alert__close js_close-alert" aria-label="Close" onClick={() => {
                                    this.setState({ warningEmail : false });
                                }}>
                                    <svg className="alert__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                                </button>
                            </div>
                            : "" }
                        </div>
                        <div className="form-group">
                            <div className="row gutter-5 align-items-center">
                                <div className="col-md-4 mb-2 mb-md-0">
                                    <label className="label">Your phone *</label>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group__input-wrapper">
                                        <Field name="phone" type="tel" className="form-control" onChange={(e) => {
                                            this.setState({ phone: this.filterText(e.target.value) });
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row gutter-5">
                                <div className="col-md-4 mb-2 mb-md-0">
                                    <label className="label mt-2">Your message</label>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group__input-wrapper">
                                        <textarea name="message" className="form-control" type="" placeholder="I would like to ask for a quote. I would need..." onChange={(e) => {
                                            this.setState({ message: this.filterText(e.target.value)});
                                            }}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--2">
                        <p className="mb-5">We take your privacy seriously. Find out more on how we use and protect your personal data. Read our full privacy notice <a href="https://www.wikov.com/en/privacy-policy-statement" target="_blank" rel="noopener noreferrer">here</a>.</p>
                        <div className="text-center text-md-right">
                            <button type="button" disabled={(this.state.quoteStop === true || this.state.phone.length === 0 || this.state.email.length === 0 || this.state.loading === true) ? true : false} onClick={this.handleRequest.bind(this)} className="btn btn--primary">Send message</button>
                        </div>
                    </div>                
                </div>
                ) : (
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Quote request
                        </div>
                        <div className="form-modal__close" data-dismiss="modal">
                            <svg className="form-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <p className="mb-5"> Thank you for your interest in our solutions. Your RFQ was successfully sent to Wikov. Our sales will contact you soon.</p>
                    </div>                
                </div>
                )}
            </div>
        </div>

        <div className="modal fade form-modal" id="contact-us" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered form-modal__dialog" role="document">
            {(!this.state.isContactUsSubmitted) ? (
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Contact us
                        </div>
                        <div className="form-modal__close" data-dismiss="modal">
                            <svg className="form-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <div className={(this.state.quoteStop) ? "form-group form-group--has-error" : "form-group" }>
                            <div className="row gutter-5 align-items-center">
                                <div className="col-md-4 mb-2 mb-md-0">
                                    <label className="label">Your e-mail address *</label>
                                </div>
                                <div className="col-md-8 mb-2 mb-md-0">
                                    <div className="form-group__input-wrapper">
                                        <Field name="email" type="email" className="form-control" onChange={(e) => {
                                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(e.target.value)) {
                                            this.setState({ quoteStop: true });
                                        }
                                        else {
                                            this.setState({ quoteStop: false, email: this.filterText(e.target.value) });
                                        }
                                        }} />
                                        {(this.state.quoteStop) ? <div className="form-group__warning-sign js_warning-sign" data-target="#alert-2" onClick={() => {
                                            this.setState({ warningEmail: true });
                                        }}></div> : "" }
                                    </div>
                                </div>
                            </div>
                            {(this.state.warningEmail) ? 
                            <div className="alert alert-error js_alert" role="alert" id="alert-2">
                                The format of e-mail is incorrect.
                                <button type="button" className="alert__close js_close-alert" aria-label="Close" onClick={() => {
                                    this.setState({ warningEmail : false });
                                }}>
                                    <svg className="alert__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                                </button>
                            </div>
                            : "" }
                        </div>
                        <div className="form-group">
                            <div className="row gutter-5 align-items-center">
                                <div className="col-md-4 mb-2 mb-md-0">
                                    <label className="label">Your phone *</label>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group__input-wrapper">
                                        <Field name="phone" type="tel" className="form-control" onChange={(e) => {
                                            this.setState({ phone: this.filterText(e.target.value) });
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row gutter-5">
                                <div className="col-md-4 mb-2 mb-md-0">
                                    <label className="label mt-2">Your message</label>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group__input-wrapper">
                                        <textarea name="message" className="form-control" type="" placeholder="I would like to ask for a help with completing the WinGear configuration..." onChange={(e) => {
                                            this.setState({ message: this.filterText(e.target.value)});
                                            }}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--2">
                        <p className="mb-5">We take your privacy seriously. Find out more on how we use and protect you personal data. Read our full privacy notice <a href="https://www.wikov.com/en/privacy-policy-statement" target="_blank" rel="noopener noreferrer">here</a>.</p>
                        <div className="text-center text-md-right">
                            <button type="button" disabled={(this.state.quoteStop === true || this.state.phone.length === 0 || this.state.email.length === 0 || this.state.loading === true) ? true : false} onClick={this.handleContact.bind(this)} className="btn btn--primary">Send message</button>
                        </div>
                    </div>                
                </div>
                ) : (
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Contact us
                        </div>
                        <div className="form-modal__close" data-dismiss="modal">
                            <svg className="form-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <p className="mb-5"> Thank you for your interest in our solutions. Your message was successfully sent to Wikov. Our sales will contact you soon.</p>
                    </div>                
                </div>
                )}
            </div>
        </div>

        {(this.state.isWarning1Shown) ? (
        <div className="modal fade form-modal show" id="warning1" tabIndex="-1" role="dialog" aria-modal="true" style={{paddingRight: '12px', display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered form-modal__dialog" role="document">                
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Warning
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <p className="mb-5s">The gearbox is not able to sufficiently cool itself. External cooling is required. Contact us if you are interested in a customized solution.</p>
                    </div>    
                    <div className="form-modal__section form-modal__section--2">                        
                        <div className="text-center text-md-right">
                            <button type="button" className="btn btn--primary" onClick={() => {
                                this.setState({isWarning1Shown: false, modified: true, externalCooling: true});
                            }}>Continue with external cooling</button>
                            <span>&nbsp;</span>
                            <button type="button" className="btn btn--primary" data-toggle="modal" data-target="#contact-us" onClick={() => {
                                this.setState({isWarning1Shown: false})}
                                }>Contact us</button>
                        </div>
                    </div>              
                </div> 
            </div>
        </div>
        ) : "" }

        {(this.state.isWarning2Shown) ? (
        <div className="modal fade form-modal show" id="warning2" tabIndex="-1" role="dialog" aria-modal="true" style={{paddingRight: '12px', display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered form-modal__dialog" role="document">                
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Warning
                        </div>
                        <div className="form-modal__close" data-dismiss="modal" onClick={() => {
                                this.setState({isWarning2Shown: false})
                            }}>
                            <svg className="form-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
                        </div>
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <p className="mb-5">The size of the gearbox you are requesting is below the catalogue range. It is too small. Contact us if you are interested in a customized solution.</p>
                    </div>    
                    <div className="form-modal__section form-modal__section--2">                        
                        <div className="text-center text-md-right">
                            <button type="button" className="btn btn--primary" onClick={() => {
                                this.setState({isWarning2Shown: false})
                            }}>Continue anyway</button>
                            <span>&nbsp;</span>
                            <button type="button" className="btn btn--primary" data-toggle="modal" data-target="#contact-us" onClick={() => {this.setState({isWarning2Shown: false})}}>Contact us</button>
                        </div>
                    </div>              
                </div> 
            </div>
        </div>
        ) : "" }

        {(this.state.isWarning3Shown) ? (
        <div className="modal fade form-modal show" id="warning3" tabIndex="-1" role="dialog" aria-modal="true" style={{paddingRight: '12px', display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered form-modal__dialog" role="document">                
                <div className="modal-content form-modal__content">
                    <div className="form-modal__header">
                        <div className="form-modal__logo">
                            <svg className="form-modal__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>
                            Warning
                        </div>                        
                    </div>
                    <div className="form-modal__section form-modal__section--1">
                        <p className="mb-5">The size of the gearbox you are requesting is above the catalogue range. It is too big. Contact us for a customized solution or choose planetary gearbox from our Orbi&#8209;Flex<sup>&reg;</sup> configurator.</p>
                    </div>    
                    <div className="form-modal__section form-modal__section--2">                        
                        <div className="text-center text-md-right">
                            <button type="button" className="btn btn--secondary" onClick={() => {
                                this.setState({isWarning3Shown: false});
                                window.location.href = '/orbiflex';
                            }}>Continue with Orbi&#8209;Flex</button>
                            <span>&nbsp;</span>
                            <button type="button" className="btn btn--primary" data-toggle="modal" data-target="#contact-us" onClick={() => {this.setState({isWarning3Shown: false})}}>Contact us</button>
                        </div>
                    </div>              
                </div> 
            </div>
        </div>
        ) : "" }

        { (this.state.shaftSelection) ? (
        <div className={(model.type === 'helical') ? "type-modal js_type-modal type-modal--opened" : "type-modal type-modal--4-cols js_type-modal type-modal--opened" } style={{ overflow: 'auto', height: '100%' }}>
            <div className="type-modal__close js_type-modal-close" onClick={() => {this.setState({ shaftSelection: false })}}>
                <svg className="type-modal__close-icon"><use xlinkHref="images/svg/svg-sprite.svg#cross"></use></svg>
            </div>
            {(model.type === 'helical') ? (
            <div className="type-modal__content">
                <h2 className="type-modal__headline">choose gearbox type</h2>
                <div className="type-modal__items">
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-1" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HRL';
                            }}/>
                        <label htmlFor="type-1" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HRL.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-2" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HDL';
                            }}/>
                        <label htmlFor="type-2" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HDL.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-3" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HLL';
                            }}/>
                        <label htmlFor="type-3" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HLL.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-4" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HRD';
                            }}/>
                        <label htmlFor="type-4" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HRD.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-5" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HDD';
                            }}/>
                        <label htmlFor="type-5" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HDD.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-6" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HLD';
                            }}/>
                        <label htmlFor="type-6" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HLD.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-7" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HRR';
                            }}/>
                        <label htmlFor="type-7" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HRR.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-8" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HDR';
                            }}/>
                        <label htmlFor="type-8" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HDR.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-9" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HLR';
                            }}/>
                        <label htmlFor="type-9" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HLR.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-10" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HRH';
                            }}/>
                        <label htmlFor="type-10" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HRH.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-11" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HDH';
                            }}/>
                        <label htmlFor="type-11" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HDH.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-12" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeHelical = 'HLH';
                            }}/>
                        <label htmlFor="type-12" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/HLH.png')"}}></span>
                        </label>
                    </div>
                </div>
            </div>
            ) : (
                <div className="type-modal__content">
                <h2 className="type-modal__headline">choose gearbox type</h2>
                <div className="type-modal__items">
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-1" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BFL';
                            }}/>
                        <label htmlFor="type-1" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BFL.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-2" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BFD';
                            }}/>
                        <label htmlFor="type-2" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BFD.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-3" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BFR';
                            }}/>
                        <label htmlFor="type-3" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BFR.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-4" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BFH';
                            }}/>
                        <label htmlFor="type-4" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BFH.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-5" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BTL';
                            }}/>
                        <label htmlFor="type-5" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BTL.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-6" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BTD';
                            }}/>
                        <label htmlFor="type-6" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BTD.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-7" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BTR';
                            }}/>
                        <label htmlFor="type-7" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BTR.png')"}}></span>
                        </label>
                    </div>
                    <div className="type-modal__item js_type-modal-close">
                        <input type="radio" name="type" id="type-8" className="type-modal__input" onClick={() => {
                            this.setState({ shaftSelection: false });
                            model.shaftTypeBevel = 'BTH';
                            }}/>
                        <label htmlFor="type-8" className="type-modal__label">
                            <span className="type-modal__image" style={{backgroundImage: "url('images/BTH.png')"}}></span>
                        </label>
                    </div>                   
                </div>
            </div>
            )}
        </div>
        ) : "" }

        <div id="js_loading" className={
            (this.state.industry_options.length === 0 || 
                this.state.primemover_options.length === 0 ||
                this.state.elevation_options.length === 0 ||
                this.state.peaksperhour_options === 0 ||
                this.state.positioning_options === 0 ||
                this.state.lubrication_options === 0 ||
                this.state.highspeedshaft_options === 0 ||
                this.state.lowspeedshaft_options === 0 ||
                this.state.climate_options === 0 ||
                this.state.loading === true) ?
            "loading loading--shown" : "loading"}></div>
        
    </Form>
    {(this.state.modified === true ) ? (
    <div className="toolbar toolbar--is-visible">    
        <button name="mobile-calculate" className={(model.gearboxParams.ratio <= 0 || model.ka <= 0 || model.gearboxParams.pwr <= 0 || model.gearboxParams.hst <= 0 || model.gearboxParams.lst <= 0 || this.checkInvalidRatio() === true) ? 'btn btn--secondary btn--disabled toolbar__btn js_scroll-to' : 'btn btn--secondary toolbar__btn js_scroll-to'} disabled={this.state.stop} onClick={this.handleSubmit.bind(this)}>{(this.state.summary === true) ? 'Recalculate' : 'Calculate' }</button>
    </div>
    ) : ( '' )}
    {(this.state.isWarning1Shown || this.state.isWarning2Shown) || this.state.isWarning3Shown ? (
    <div className="modal-backdrop fade show"></div>
    ) : "" }
    </React.Fragment>
        )}
        </Formik>
    );
  }
}

export default Configurator;