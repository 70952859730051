import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './css/main.css';
import './scss/main.scss';
import Orbiflex from './configurator';
import Wingear from './wingear';
import Header from './components/header';
import Home from './home';
import Stats from './stats/stats';
  
class App extends Component {

    render() {
        return (
            <React.Fragment>   
                <Header />             
                <BrowserRouter>                
                <div>
                    <main className="main">
                        <div className="container">
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/orbiflex" exact component={Orbiflex} />
                                <Route path="/wingear" exact component={Wingear} />
                                <Route path="/stats" exact component={Stats} />
                            </Switch>
                        </div>

                        <div className="loading" id="js_loading" onClick="hideLoading()"></div>
                    </main>
                    </div>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default App;