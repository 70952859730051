import React, { Component } from 'react';
import { config } from './config';

let basic = 'Basic d2VidWk6d2lrb3Y=';

class Home extends Component {
    componentWillMount() {
        fetch(config.server + '/api/access?destination=home', {
            headers: new Headers({
            "Authorization": basic
            }),
        })
        .then(() => {});
    }

    render() {
        return (
            <section class="padding-tb-1">
                <div class="container">
                    <div class="tiles">
                        <div class="tiles__tile">
                            <a href="/orbiflex" class="tiles__tile-content">
                                <div class="tiles__tile-image-wrapper">
                                    <img src="images/hp-orbiflex.png" alt="Orbi-FleX" class="tiles__tile-image" />
                                </div>
                                <h2 class="tiles__tile-headline">Orbi-fleX<sup>&reg;</sup> Gearbox</h2>
                                <div class="tiles__tile-btn-container">
                                    <button class="btn btn--secondary">Open configurator</button>
                                </div>
                            </a>
                        </div>
                        <div class="tiles__tile">
                            <a href="/wingear" class="tiles__tile-content">
                                <div class="tiles__tile-image-wrapper">
                                    <img src="images/hp-wingear.png" alt="WinGear" class="tiles__tile-image" />
                                </div>
                                <h2 class="tiles__tile-headline">WinGear Gearbox</h2>
                                <div class="tiles__tile-btn-container">
                                    <button class="btn btn--secondary">Open configurator</button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Home;