
import React, { Component } from 'react';

const path = window.location.pathname;

class Home extends Component {
    render() {
        return (
            <header className="header">
                <div className="container header__container">
                    <a href="https://www.wikov.com" className="header__logo">
                        <svg className="header__logo-icon"><use xlinkHref="images/svg/svg-sprite.svg#logo"></use></svg>{ (path === '/orbiflex') ? ( <React.Fragment><span>Orbi-fleX</span><sup>&reg;</sup></React.Fragment> ) : (path === '/wingear') ? ( <React.Fragment><span>WinGear</span></React.Fragment> ) : "" } Calculator{(path === '/') ? "s" : ""}
                    </a>
                    <div className="header__right">                        
                            {(path === '/orbiflex') ? (
                            <div class="header__buttons">
                            <a href="/orbiflex" className="btn btn--secondary d-none d-md-inline-block" style={{marginRight: "1.0em"}}>
                                Reset
                            </a>
                            <a href="https://www.wikov.com/file/edee/prilohy/orbi-flex_catalogue.pdf" target="_blank" rel="noopener noreferrer" className="btn btn--tertiary btn--has-icon d-none d-md-inline-block">
                                Download catalogue
                                <svg className="btn__icon"><use xlinkHref="images/svg/svg-sprite.svg#catalog"></use></svg>
                            </a>
                            </div>
                            ) : (path === '/wingear') ? (
                            <div class="header__buttons">
                                <a href="user-guide.html" class="btn btn--quinary btn--has-icon btn--yt" target="_blank">
                                    User guide
                                    <svg class="btn__icon"><use xlinkHref="images/svg/svg-sprite.svg#youtube"></use></svg>
                                </a>
                                <a href="/wingear" className="btn btn--secondary d-none d-md-inline-block">
                                    Reset
                                </a>
                            </div>
                            ) : '' }
                        {(path !== '/') ? (
                        <div className="header__select">
                            <select onChange={(e) => 
                                window.location.href = `/${e.target.value}`
                            }>
                                <option value="1">Choose other calculator</option>
                                {(path === '/wingear') ? (
                                <option value="orbiflex">Orbi-fleX</option>
                                ) : (path === '/orbiflex') ? (
                                <option value="wingear">WinGear</option>
                                ) : (
                                <React.Fragment>
                                <option value="orbiflex">Orbi-fleX</option>
                                <option value="wingear">WinGear</option>
                                </React.Fragment>
                                )}
                            </select>
                        </div>
                        ) : "" }
                    </div>            
                </div>
            </header>
        );
    }
}

export default Home;